"use client";
import { formatNumber } from "@/helpers/formatters";
import _e from "@/helpers/texts";

export default function ProductLabel({ caption, selected, price, cancelledPrice, isRecommended, onClick }: {
  readonly caption: string,
  readonly selected: boolean,
  readonly price: number,
  readonly cancelledPrice: number,
  readonly isRecommended?: boolean,
  readonly onClick?: Function,
}) {
  const handleClick = (e: any) => {
    if(onClick) onClick(e);
  };

  return (
    <div className="relative flex justify-start items-stretch bg-[#20318E] rounded-2xl p-3 mt-[10px] shadow-[0px_1px_1px_0px_rgba(0,0,0,0.35)_inset] drop-shadow-[0px_2px_2px_rgba(0,0,0,0.26)] cursor-pointer" onClick={ handleClick }>
      <div className="flex items-center pe-3">
        <span className="flex items-center bg-[#FAF9FF] w-8 h-8 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)] rounded-full">
          {
            selected && (
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="-ml-[2px]" fill="none">
                <g filter="url(#filter0_d_190_24830)">
                  <path d="M27.4244 12.5149L27.777 12.1616L27.4243 11.8083L24.2668 8.64668L23.9131 8.29246L23.5593 8.64665L16.1314 16.0829L12.5126 12.4621L12.159 12.1083L11.8053 12.462L8.64638 15.6222L8.29319 15.9755L8.64621 16.329L12.6202 20.3083L12.6203 20.3084L15.7777 23.4715L16.1314 23.8258L16.4852 23.4716L19.6457 20.3084L19.6459 20.3083L27.4244 12.5149Z" fill="#FC6A00" stroke="black"/>
                </g>
                <defs>
                  <filter id="filter0_d_190_24830" x="0" y="0" width="35" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_190_24830"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_190_24830" result="shape"/>
                  </filter>
                </defs>
              </svg>
            )
          }
        </span>
      </div>

      <div className="relative text-[#FAF9FF]">
        <div className={`text-[13px] font-bold uppercase`}>{ caption }</div>
        <div className="">
          <span className={`${isRecommended ? 'text-[#FC6A00]' : ''} me-2 text-[20px] font-extrabold`}>{ formatNumber(price) } TL</span>
          <span className="text-[#D0D0D0] font-medium line-through">{ formatNumber(cancelledPrice) } TL</span>
        </div>
        <div className="text-[#D0D0D0] text-[13px]">
          { _e('Vade farksız') } { _e('ayda :count TL', { count: formatNumber(price / 12) }) } { _e('taksitle') }
        </div>
      </div>
      
      {
        isRecommended && (
          <div className="absolute text-[11px] text-[#FAF9FF] right-3 top-3 rounded-full bg-[#FF820A] px-2 py-1">
            { _e('Önerilen') }
          </div>
        )
      }
    </div>
  );
}