"use client";

import DPIcon from '@/components/DPIcon/DPIcon';
import Image from 'next/image';

export default function ImageList({ caption, testimonials, lbSelect, maxItems }: {
  readonly caption: string,
  readonly testimonials: Record<string, any>[],
  readonly lbSelect: Function,
  readonly maxItems?: number,
}) {
  if(!maxItems) maxItems = testimonials?.length ?? 9999;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {testimonials?.slice(0, maxItems)?.map((testimonial: Record<string,any>) => (
        <div 
          className="image-card cursor-pointer group relative" 
          key={testimonial.id} 
          onClick={() => lbSelect(testimonial.image_url)}
        >
          <div className="relative before:relative before:block before:pb-[75%]">
            <Image
              fill
              src={testimonial.thumbnail_url ?? testimonial.image_url} 
              alt={testimonial?.caption ?? testimonial?.name ?? 'Doping Hafıza'}
              style={{ objectFit: 'cover', objectPosition: 'top' }}
              sizes='100% 100%'
              loading="lazy"
            />
          </div>
          <div className="p-2 mt-2 bg-cornflower-blue rounded-md flex flex-col text-center">
            <p className="text-sm text-white">{ caption }</p>
            <p className="text-sm font-semibold text-white">{ testimonial?.name }</p>
            <p className="text-sm text-white">{ testimonial?.caption }</p>
          </div>
          <DPIcon icon="search-article" className="mx-1 text-charcoal group-hover:text-lava transition-all duration-500 text-2xl absolute right-2 top-2" />
        </div>
      ))}
    </div>
  );
}
