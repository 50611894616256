"use client";

import { useEffect, useRef, useState } from "react";
import DPIcon from "../DPIcon/DPIcon";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import _e from "@/helpers/texts";
import { formatNumber } from "@/helpers/formatters";
import { stripHTML, stripTags } from "@/helpers";
import { addToCart } from "../CartLink/CartLink";
import { useRouter } from "next/navigation";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";

export default function BuyNowFooter({ price, cancelledPrice, image, caption, content, external_id, bgColor, ecommerceId, categoryName, breakPoint, mobileBreakPoint, comingSoon }: {
  readonly price: number,
  readonly cancelledPrice?: number,
  readonly image: string,
  readonly caption: string,
  readonly content: string,
  readonly external_id: string,
  readonly bgColor: string,
  readonly ecommerceId: string,
  readonly categoryName: string,
  readonly breakPoint?: number,
  readonly mobileBreakPoint?: number,
  readonly comingSoon?: boolean,
}) {
  const router = useRouter();

  const dialogRef = useRef<any>(null);

  const fallbackImage = `${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/webgorsel/tyt-tum-dersler.png`;

  const [ stickyState, setStickyState ] = useState(false);
  const [ toggleState, setToggleState ] = useState(false);
  const [ scrollBreakPoint, setScrollBreakPoint ] = useState(440);

  useEffect(() => {
    const handleScroll = () => {
      try {
        const bp = window?.innerWidth > 1024 ? (breakPoint ?? 440) : mobileBreakPoint ?? 440;
  
        if (window?.scrollY > bp && !stickyState) {
          setStickyState(true);
        } else if (window?.scrollY <= bp && stickyState) {
          setStickyState(false);
        }
  
        setScrollBreakPoint(bp);
      } catch(e) {}
    };

    handleScroll();

    window?.addEventListener('scroll', handleScroll);
    return () => window?.removeEventListener('scroll', handleScroll);
  }, [
    stickyState,
    breakPoint,
    mobileBreakPoint
  ]);

  return (
    <>
      <BuyInfoDialog ref={dialogRef} />
      <div className={`fixed z-[997] bottom-0 left-0 right-0 w-full bg-alice-blue pt-4 pb-[12px] md:pb-[56px] lg:pb-[30px] transition-all duration-300 ${stickyState ? 'shadow-[0_-10px_20px_rgba(var(--charcoal-value),0.2)]' : 'translate-y-full'}`} data-footer-scroll-break-point={scrollBreakPoint} data-wa-bottom={toggleState ? 242 : 122}>
        <div className="container flex flex-col lg:flex-row items-stretch lg:items-center">

          <div className="text-price-success text-sm -translate-y-2 block lg:hidden">
            {
              comingSoon ? (
                <>
                  <DPIcon icon="warning" className="me-1" />
                  <span dangerouslySetInnerHTML={{ __html: _e('<strong>:name</strong> paketimiz yakında satışa sunulacaktır.', { name: stripTags(caption ?? '') }) }}></span>
                </>
              ) : (
                <>
                  <DPIcon icon="warning" className="me-1" />
                  { _e('Peşin Fiyatına Vade Farksız') } <strong className="whitespace-nowrap">{ _e('Ayda :count TL', { count: formatNumber(price / 12) }) }</strong> { _e('Taksitle') }
                </>
              )
            }
          </div>

          <div className={`flex items-center lg:border-r-[1px] ${toggleState ? 'h-[108px] mb-3' : 'h-0 mb-0'} transition-all overflow-hidden lg:!h-[108px] lg:!mb-0`}>
            <div className="border-[1px] border-dashed border-gray-300 rounded-xl p-4">
              <div className="relative w-[74px] h-[74px] overflow-hidden" style={{ backgroundColor: bgColor ?? 'transparent' }}>
                <ImageWithFallback
                  src={image}
                  fallback={fallbackImage}
                  alt="Doping Hafıza"
                  fill={true}
                  sizes="100%"
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                />
              </div>
            </div>
            <div className="relative lg:w-[320px] px-6 max-h-[108px] overflow-hidden after:block after:absolute after:left-0 after:right-0 after:bottom-0 after:h-4 after:bg-gradient-to-b from-transparent to-alice-blue after:z-10">
              <h5 className="text-[15px] font-bold pe-9 mt-2 mb-1">{caption}</h5>
              <p className="text-[11px] leading-relaxed mb-2">
                {stripHTML(content, 10)}
              </p>
            </div>
          </div>

          <div className="flex items-center flex-grow">
            <div className="ps-0 pe-6 lg:px-6 block">
              <div className="flex flex-col lg:flex-row justify-start flex-wrap items-baseline">
                {
                  comingSoon ? (
                    <>
                      <span className="text-honolulu-blue text-[24px] lg:text-[28px] xl:text-[34px] font-bold lg:pe-3 whitespace-nowrap">
                        { _e('Yakında Gelecek') }
                        <span className="ps-2 lg:hidden" onClick={ () => setToggleState((current) => !current) }>
                          <DPIcon icon="arrow-fill-circle-up" className={`inline-block text-slate-400 transition-all cursor-pointer ${toggleState ? 'rotate-180' : ''}`} />
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-honolulu-blue text-[24px] lg:text-[28px] xl:text-[34px] font-bold lg:pe-3 whitespace-nowrap">
                        { formatNumber(price) }&nbsp;TL
                        <span className="ps-2 lg:hidden" onClick={ () => setToggleState((current) => !current) }>
                          <DPIcon icon="arrow-fill-circle-up" className={`inline-block text-slate-400 transition-all cursor-pointer ${toggleState ? 'rotate-180' : ''}`} />
                        </span>
                      </span>
                      {
                        cancelledPrice && (
                          <span className="text-lava text-lg font-semibold line-through">{ formatNumber(cancelledPrice) }&nbsp;TL</span>
                        )
                      }
                    </>
                  )
                }
              </div>

              <div className="text-price-success text-sm hidden lg:block">
                {
                  comingSoon ? (
                    <>
                      <DPIcon icon="warning" className="me-1" />
                      <span dangerouslySetInnerHTML={{ __html: _e('<strong>:name</strong> paketimiz yakında satışa sunulacaktır.', { name: stripTags(caption ?? '') }) }}></span>
                    </>
                  ) : (
                    <>
                      <DPIcon icon="warning" className="me-1" />
                      { _e('Peşin Fiyatına Vade Farksız') } <strong className="whitespace-nowrap">{ _e('Ayda :count TL', { count: formatNumber(price / 12) }) }</strong> { _e('Taksitle') }
                    </>
                  )
                }
              </div>
            </div>

            {
              !comingSoon ? (
                <div className="flex justify-end flex-grow">
                  <button className="btn btn-blue whitespace-nowrap w-full md:w-auto !px-4 md:!px-8 !text-sm lg:!text-base" data-ecommerce-id={ecommerceId} onClick={() => addToCart(external_id, ecommerceId, price, caption, categoryName, router)}>
                    { _e('Hemen satın al') }
                  </button>
                </div>
              ) : (
                <div className="flex justify-end flex-grow">
                  <button className="btn btn-blue whitespace-nowrap w-full md:w-auto !px-4 md:!px-8 !text-sm lg:!text-base" data-ecommerce-id={ecommerceId} onClick={() => { dialogRef?.current?.open(stripTags(caption ?? ''), stripTags(caption ?? '')); }}>
                    { _e('Bilgi Bırak') }
                  </button>
                </div>
              )
            }
          </div>
          
        </div>
      </div>
    </>
  );
}
