"use client";
import { purify } from "@/helpers";
import "./css/stdPageMain.scss";

export default function StdPageMain({ data }: Record<string, any>) {
  return (
    <div className="relative __std-page-main-container">
      <h1 className="text-[28px] leading-normal font-semibold mb-4 text-gunmetal">{data?.view?.caption ?? ''}</h1>
      <div className="content" dangerouslySetInnerHTML={purify(data?.view?.content ?? '')}></div>
    </div>
  );
}
