"use client";

import DayCalcList from "@/components/DayCalcList/DayCalcList";

export default function CalcMain({ data }: {
  data: Record<string,any>
}) {
  return (
    <section className="mb-16">
      <DayCalcList
        list={ data?.calc_groups }
        className="!mt-0"
        heading={{
          caption: data?.day_calc_caption ?? '',
          subcap: data?.day_calc_subcap ?? '',
          strongCap: data?.day_calc_strong_cap ?? '',
          bgUrl: data?.day_calc_bg_url ?? '',
        }}
      />
    </section>
  );
}
