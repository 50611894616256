"use client";
import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import { linkUrl } from "@/helpers";
import { formatDateDiff, formatUrl } from "@/helpers/formatters";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function ArticleCard({ image, link, caption, category, date, className, imgRoot }: {
  readonly image: string,
  readonly caption: string,
  readonly link: string,
  readonly category?: string,
  readonly date?: string,
  readonly className?: string,
  readonly imgRoot?: string,
}) {
  const fallback_url = "https://assets-dopinghafiza.dopingtech.net/assets/files/bizden-haberler/1(3).jpg";
  const imgDir = imgRoot ?? 'blog';

  return (
    <div className={`p-[15px] shadow-default hover:shadow-lg duration-500 rounded-md bg-white group flex flex-col justify-between items-stretch ${className ?? ''}`}>
      <div className="relative">
        <LoadingLink href={formatUrl(link, true)} className="relative block pb-[56.25%]">
          <ImageWithFallback
            src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/' + imgDir + '/', image)}
            fallback={fallback_url}
            alt={caption}
            fill={true}
            sizes="100%"
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </LoadingLink>
        <LoadingLink href={formatUrl(link, true)}>
          <h3 className={`group text-dark-electric-blue font-semibold group-hover:text-lava mb-[30px] transition-all duration-300 mt-[15px] hover:text-lava`}>
            { caption }
          </h3>
        </LoadingLink>
      </div>
      <div className="border-t  pt-[15px] flex items-center justify-between">
        <span className="text-dark-electric-blue text-xs">{ category ?? '' }</span>
        <p className="text-xs text-manatee">{ formatDateDiff(date, true) }</p>
      </div>
    </div>
  );
}
