"use client";

import Image from "next/image";

export default function CommonButton({ color, small, children, className, width, height, onClick }: {
  readonly color?: 'orange' | 'blue' | 'purple' | 'red' | 'transparent',
  readonly small?: boolean,
  readonly className?: string,
  readonly children: React.ReactElement|string,
  readonly width?: string,
  readonly height?: string,
  readonly onClick?: Function,
}) {
  const handleClick = (e: any) => {
    if(onClick) onClick(e);
  };

  const bg: Record<string,any> = {
    'orange': {
      background: 'linear-gradient(90deg, #FF8008 0%, #FFBB37 100%), linear-gradient(90deg, #F76E0C 0%, #FF9F2C 100%)',
      border: '1px solid #E26100',
      boxShadow: '0px -3px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset',
    },
    'blue': {
      background: 'linear-gradient(90deg, #0085FF 0%, #00C6FF 100%)',
      border: '1px solid #0D4484',
      boxShadow: '0px -3px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset',
    },
    'purple': {
      background: 'linear-gradient(302deg, #A152FF -71.57%, #DF49DB 184.05%)',
      border: '1px solid #9833C6',
      boxShadow: '0px 1px 2px 0px rgba(174, 198, 255, 0.08)',
    },
    'red': {
      background: '#E74C3C',
      boxShadow: '0px -3px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 2px 0px 0px rgba(53, 219, 255, 0.30) inset',
    },
    'transparent': {
      background: 'transparent',
    },
  }[color ?? 'orange'];

  bg.width = width ?? 'auto';
  bg.height = height ?? (small ? '36px' : '48px');
  bg.minHeight = height ?? (small ? '36px' : '48px');
  if(color !== 'transparent') bg.textShadow = '0px 0px 2px rgba(0, 0, 0, .9)';

  return (
    <div
      className={`relative flex justify-center items-center cursor-pointer select-none ${small ? 'rounded-[16px]' : 'rounded-[14px]'} ${className ?? ''} ${color !== 'transparent' ? 'font-bold' : ''} text-white text-[16px] tracking-[0.16px]`}
      style={ bg }
      onClick={handleClick}
    >
      {
        color !== 'transparent' && (
          <>
            <Image
              src={"/assets/images/ilkokul/btn-left-top.svg"}
              alt=" "
              width={small ? 13 : 19}
              height={small ? 11 : 13}
              className="absolute top-[4px] left-[4px]"
            />
            <Image
              src={"/assets/images/ilkokul/btn-right-bottom.svg"}
              alt=" "
              width={small ? 10 : 11}
              height={small ? 7 : 8}
              className="absolute bottom-[7px] right-[5px]"
            />
          </>
        )
      }

      <div className="relative">
        { children }
      </div>
    </div>
  );
}
