"use client";

import { findAndStopAllVideos, videoFunctions } from "@/components/HomeSlider/functions";
import JsonLd from "@/components/JsonLd/JsonLd";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { linkUrl } from "@/helpers";
import Image from "next/image";
import { useState } from "react";
import FsLightbox from "fslightbox-react";

export default function TestimonialFullList({ videoList, testimonialList }: {
  readonly videoList: Record<string,any>[]
  readonly testimonialList: Record<string,any>[]
}) {
  const [ videos, setVideos ] = useState<{ id: string, video: HTMLVideoElement }[]>([]);

  const fn = videoFunctions({ videos, setVideos });

  const [ lightboxController, setLightboxController ] = useState({
    toggler: false,
    slide: 1
  });

  const openLightbox = (id: number) => {
    findAndStopAllVideos();
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: id
    });
  };

  const lightBoxSource: string[] = [];
  const listSource: Record<string,any>[] = [];
  testimonialList.forEach((item: Record<string,any>) => {
    lightBoxSource.push(linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/files/yorumlar/', item.image_url) ?? '');
    listSource.push({ ...item });
  });
  
  return (
    <div className="flex justify-start items-stretch flex-wrap -mx-3">

      {
        videoList?.length ? videoList?.map((item) => (
          <div key={item.id} className="flex items-stretch p-3 w-full md:w-1/2 lg:w-1/3">
            <div className="p-3 relative shadow-md rounded-lg flex flex-col justify-between bg-white">
              <div className="flex-grow-0">
                <VideoPlayer
                  src={ linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/', item.video_url ?? '') ?? '' }
                  poster={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/videogorsel/', item.video_poster_url ?? '') ?? '' }
                  onAdd={fn.addVideo}
                  onRemove={fn.removeVideo}
                />
              </div>
              <div className="flex-grow mt-3 text-base font-semibold flex items-start border-t border-t-slate-200 pt-2">
                { item?.caption }
              </div>
            </div>
          </div>
        )) : null
      }
      
      {
        listSource?.length ? listSource?.map((item: Record<string,any>, index: number) => (
          <div key={item.id} className="flex items-stretch p-3 w-full md:w-1/2 lg:w-1/3">
            <div className="p-3 relative shadow-md rounded-lg flex flex-col justify-between w-full bg-white">
              <div className="relative aspect-video cursor-pointer flex-grow-0" onClick={ () => openLightbox(index + 1) }>
                <Image
                  src={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/files/yorumlar/thumbnail/', item.thumbnail_url ?? item.image_url ?? '') ?? ''}
                  alt={item.name }
                  fill={true}
                  sizes="100%"
                  style={{ objectFit: 'cover', objectPosition: 'top center' }}
                />
              </div>
              <div className="flex-grow mt-3 text-base font-semibold flex items-start border-t border-t-slate-200 pt-2">
                { item.name }, { item.commentator_type }
              </div>
            </div>
          </div>
        )) : null
      }
      
      {
        listSource?.length ? (
          <FsLightbox
            toggler={lightboxController.toggler}
            slide={lightboxController.slide}
            sources={lightBoxSource}
            types={lightBoxSource.map(() => 'image')}
          />
        ) : null
      }
      
      {
        videoList?.length ? (
          <JsonLd data={{
            "@context": "https://schema.org",
            "@type": "VideoGallery",
            "itemListElement": videoList?.map((item: Record<string,any>, index: number) => {
              return {
                "@type": "VideoObject",
                "position": index + 1,
                "name": item?.caption,
                "description": item?.subcap,
                "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? '',
                "duration": item?.duration ? 'PT' + Math.floor((item.duration ?? 0) / 60) + 'M' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
                "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? '',
                "uploadDate": item.publishedAt,
              };
            })
          }} />
        ) : null
      }
    </div>
  );
}