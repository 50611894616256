"use client";

import LoadingLink from "@/components/LoadingLink/LoadingLink";
import WAWidget from "@/components/WAWidget/WAWidget";
import { LiveChatWidget } from "@livechat/widget-react";
import Image from "next/image";
import { useEffect, useState } from "react";

export default function IlkokulFooter({ menuData }: {
  readonly menuData: Record<string,any>,
}) {
  const [ isMobile, setIsMobile ] = useState<boolean>(true);
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      let mob = true;
      if(window && window?.innerWidth >= 1024) mob = false;

      setIsMobile(mob);
      setIsLoaded(true);
    };

    handleResize();

    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="relative bg-[#F3F1FF] overflow-hidden">
      <div className="container flex flex-col items-start text-start lg:items-center lg:text-center text-[#3A4759] gap-3">
        <h2 className="mt-[30px] text-[18px] font-bold leading-[26px]">Kaynaklar</h2>

        <LoadingLink className="text-[15px] transition-all hover:text-lava" href="/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</LoadingLink>
        <LoadingLink className="text-[15px] transition-all hover:text-lava" href="/bilgi-guvenligi-politikasi">Bilgi Güvenliği Politikası</LoadingLink>
        <LoadingLink className="text-[15px] transition-all hover:text-lava" href="/kvkk-aydinlatma-metni">KVKK Aydınlatma Metni</LoadingLink>

        <Image
          src={"/assets/images/ilkokul/footer-icons.png"}
          alt={"Doping Hafıza"}
          loading="lazy" //! priority={true}
          width={399}
          height={32}
          className="select-none pointer-events-none m-0 lg:m-auto max-w-[100%] translate-y-0 lg:translate-y-[180%] my-5 lg:my-0"
        />
      </div>

      <div className="absolute right-0 bottom-2 left-0 text-center text-[#F0ECFF] font-medium text-[15px] select-none">Copyright © Doping Hafıza ® 2024</div>

      <Image
        src={"/assets/images/ilkokul/footer-bg.png"}
        alt={"Doping Hafıza"}
        loading="lazy" //! priority={true}
        width={1432}
        height={211}
        className="w-[1432px] max-w-[1432px] lg:w-full lg:max-w-full select-none pointer-events-none z-0"
      />

      {
        isLoaded ? (
          isMobile ? (
            <WAWidget whatsapp={menuData?.whatsapp} phone={menuData?.phone} />
          ) : (
            <LiveChatWidget
              license="11147847"
            />
          )
        ) : (<></>)
      }
    </footer>
  );
}