"use client";
import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import Image from "next/image";
import { useCallback, useState } from "react";
import ProductLabel from "./ProductLabel";
import { purify } from "@/helpers";
import CommonButton from "./CommonButton";
import _e from "@/helpers/texts";
import { addToCart } from "@/components/CartLink/CartLink";
import { useRouter } from "next/navigation";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export default function ProductCard({ image, contentList, productList, onMarket, isMobile, caption, openContact, bgColor }: {
  readonly image: string,
  readonly contentList: Record<string,any>[],
  readonly productList: Record<string,any>[],
  readonly onMarket?: boolean,
  readonly isMobile?: boolean,
  readonly caption?: string,
  readonly bgColor?: string,
  readonly openContact: Function,
}) {
  const [ selected, setSelected ] = useState<number>();
  const [ selectedItem, setSelectedItem ] = useState<Record<string,any>>();

  const router = useRouter();

  const products = productList?.slice(0, 2)?.map((item: Record<string,any>, index: number) => {
    item.key = index + 1;
    return item;
  });

  const selectPackage = useCallback((key: number) => {
    if(selected === key) {
      setSelected(undefined);
      setSelectedItem(undefined);
    } else {
      setSelected(key);
      const sel = products?.find((product: Record<string,any>) => product.key === key);
      setSelectedItem(sel);
    }
  }, [ products, selected ]);

  return (
    <div className={`product-card ${isMobile ? '' : 'rounded-[28px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]'} overflow-hidden ${selected ? 'open' : ''}`}>
      <div
        className={`relative p-4 z-10`}
        style={{ backgroundColor: bgColor ?? '#0B1D83' }}
      >
        <Image
          src={"/assets/images/ilkokul/card-bg.png"}
          alt=" "
          loading="lazy" //! priority={true}
          width={197}
          height={265}
          className="absolute top-0 right-0 bottom-0 my-auto z-0 select-none pointer-events-none"
        />

        <div className="relative">
          {
            !isMobile && (
              <div>
                <h3 className="text-center text-[#F0ECFF] text-[20px] font-bold uppercase pb-3">{ caption }</h3>
              </div>
            )
          }
          <div className="mb-3">
            <ImageWithFallback
              src={image}
              fallback="/assets/images/ilkokul/box-items/box-image.png"
              alt={"Doping Hafıza"}
              loading="lazy" //! priority={true}
              width={345}
              height={230}
              className="w-full select-none"
            />
          </div>

          <ul className="text-[#F0ECFF] text-sm font-light list-disc ps-4 [&>li]:py-[3px]">
            { contentList?.map((item: Record<string,any>) => (
              <li key={item.key} dangerouslySetInnerHTML={purify(item.text)} />
            )) }
          </ul>
          
          {
            products?.map((product: Record<string,any>) => (
              <ProductLabel key={product.key} caption={product.caption} selected={selected === product.key} price={product.price} cancelledPrice={product.cancelledPrice} isRecommended={product.isRecommended} onClick={() => selectPackage(product.key)} />
            ))
          }
        </div>
      </div>
      <div className={`card-buttons relative flex flex-col items-stretch gap-3 bg-[#F0ECFF] p-4 z-0 overflow-hidden`}>
        {
          onMarket ? (
            <>
              <CommonButton color="orange" data-ecommerce-id={selectedItem?.ecommerceId} onClick={() => addToCart(selectedItem?.external_id, selectedItem?.ecommerceId, selectedItem?.price, selectedItem?.caption, selectedItem?.category_name, router)}>
                { _e('Hemen Satın Al') }
              </CommonButton>
              <LoadingLink href={selectedItem?.url ?? '#'}>
                <CommonButton color="blue">
                  { _e('İncele') }
                </CommonButton>
              </LoadingLink>
            </>
          ) : (
            <>
              <CommonButton color="orange" onClick={() => openContact(products?.find((product: Record<string,any>) => product.key === selected)?.caption ?? caption ?? 'Ürün Belirtilmemiş')}>
                { _e('Bilgi Bırak') }
              </CommonButton>
            </>
          )
        }
      </div>
    </div>
  );
}