import Image from "next/image";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import LoadingLink from "../LoadingLink/LoadingLink";
type Props = {
  src?: string;
  genial?: string;
  poster?: string;
  title?: string;
  videoDesc?: string;
  videoTime?: string;
  categories?: string;
  slug?: string;
  titleSize?:number;
  bgColor?: string;
};
export default function SampleVideos({
  src,
  genial,
  poster,
  title,
  videoDesc,
  videoTime,
  categories,
  slug,
  titleSize,
}: Props) {
  return (
    <div className={`p-[15px] shadow-default rounded-md mb-10 lg:mb-5 bg-white group`}>
      {genial ? (
        <div className="relative before:w-full before:pb-[56.25%] before:block">
          <iframe src={`https://view.genial.ly/${genial}`} allow="autoplay; fullscreen; clipboard-read; clipboard-write; encrypted-media; geolocation; microphone" style={{ 'position': 'absolute', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%' }}></iframe>
        </div>
      ) : (src ? (
        <VideoPlayer src={src ?? ""} poster={poster ?? ""} />
      ) : (
        <LoadingLink href={slug ?? ""}>
          <Image
            src={poster ?? `${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/webgorsel/tyt-tum-dersler.png`}
            width={500}
            height={500}
            alt={poster ?? ""}
          />
        </LoadingLink>
      ))}
      {
        (() => {
          if(slug) return (
            <LoadingLink href={slug}>
              <h3 className={`${!src && categories ? "text-lava group " : ""} font-semibold group-hover:text-lava mb-[30px] transition-all duration-300 mt-[15px] text-lava`} style={{fontSize : titleSize ?? 14}}>
                {title ?? ""} <span className="group-hover:text-lava text-charcoal transition-all duration-300">{!src ? categories : ""}</span>
              </h3>
            </LoadingLink>
          );

          return (
            <h3 className={`${!src && categories ? "text-lava" : ""} text-dark-electric-blue font-semibold mb-[30px] transition-all duration-300 mt-[15px]`} style={{fontSize : titleSize ?? 14}}>
              {title ?? ""} <span className="text-charcoal">{!src ? categories : ""}</span>
            </h3>
          );
        })()
      }
      <div className="border-t  pt-[15px] flex items-center justify-between">
        <span className="text-dark-electric-blue text-xs font-semibold">{videoDesc ?? ""}</span>
        <p className="text-xs text-manatee">{videoTime ?? ""}</p>
      </div>
    </div>
  );
}
