"use client";

import JsonLd from "@/components/JsonLd/JsonLd";
import SampleVideoItem from "@/components/SampleVideo/SampleVideo";
import { linkUrl } from "@/helpers";
import _e from "@/helpers/texts";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export default function VideoList({ caption, videos, root }: {
  caption?: string,
  root?: string,
  videos: Record<string, any>[],
}) {
  const pathname = usePathname();

  const showCount = 8;
  const [ show, setShow ] = useState<number>(1);
  const [ hasMore, setHasMore ] = useState<boolean>(false);

  const loadMore = () => {
    let current = show + 1;
    setHasMore(current * showCount < videos.length);
    setShow(current);
  };

  useEffect(() => {
    setHasMore(showCount < videos.length);
  }, [ videos.length ]);

  return (
    <div className="col-span-1 lg:col-span-8">
      <h1 className="text-2xl md:text-[28px] font-semibold mb-8">{ caption ?? '' }</h1>
      <div className="lg:bg-white grid grid-cols-1 lg:grid-cols-2 gap-x-[30px] lg:p-10 mb-10">
        {
          videos?.slice(0, show * showCount)?.map((item: Record<string,any>) => (
            <SampleVideoItem
              key={item.id}
              src={linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? ''}
              genial={item?.genial}
              poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? ''}
              title={item?.caption ?? ''}
              videoDesc={ caption ?? '' }
              videoTime={ item?.duration ? Math.floor((item.duration ?? 0) / 60) + ':' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') : '' }
              slug={item?.slug ? linkUrl(root ?? pathname, item?.slug) : undefined}
            />
          ))
        }

        <JsonLd data={{
          "@context": "https://schema.org",
          "@type": "VideoGallery",
          "itemListElement": videos?.map((item: Record<string,any>, index: number) => {
            return {
              "@type": "VideoObject",
              "position": index + 1,
              "name": item?.caption,
              "description": item?.subcap,
              "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? '',
              "duration": item?.duration ? 'PT' + Math.floor((item.duration ?? 0) / 60) + 'M' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
              "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? '',
              "uploadDate": item.publishedAt,
            };
          })
        }} />

      </div>

      <div className={`${hasMore ? 'flex' : 'hidden'} justify-center items-center py-10`}>
        <button className="btn std-btn" onClick={loadMore}>{ _e('Daha Fazla Göster') }</button>
      </div>
    </div>
  );
}
