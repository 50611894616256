"use client";

import {useEffect, useState} from 'react';
import './css/commentsAndAchievements.scss';
import DPIcon from '@/components/DPIcon/DPIcon';
import FsLightbox from "fslightbox-react";
import { usePathname } from 'next/navigation';
import ImageList from './ImageList';
import VideoList from './VideoList';
import { LinkType } from '@/components/FastMenu/FastMenu';
import _e from '@/helpers/texts';
import { linkUrl } from '@/helpers';
import SideBar from '@/components/SideBar/SideBar';
import LoadingLink from '@/components/LoadingLink/LoadingLink';

export default function TestimonialsMain({ data }: Record<string, any>) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedImage, setSelectedImageId ] = useState<number>(0);
  const [ toggler, setToggler ] = useState(false);
  
  const fsImages: string[] = [];
  const viewMain: Record<string,any>[] = [];
  data.view.view_main?.forEach((item: Record<string,any>) => {
    const tItem = { ...item };
    tItem.testimonials.splice(tItem.is_video === 'Evet' ? 4 : 6);
    viewMain.push(tItem);
    if(tItem.is_video !== 'Evet') {
      tItem.testimonials.forEach((testimonial: Record<string,any>) => {
        let thumb: string = testimonial?.thumbnail_url ?? testimonial.image_url ?? '';
        thumb = linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/yorumlar/thumbnail/', thumb) ?? '';
        let image: string = linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/yorumlar/', testimonial.image_url ?? '') ?? '';
        fsImages.push(image);

        testimonial.image_url = image;
        testimonial.thumbnail_url = thumb;
      });
    }
  });

  function selectModalImageId(url: string) {
    const id = fsImages.indexOf(url);
    setSelectedImageId(id);
    setToggler((prevToggler) => !prevToggler);
  }

  const pathname = usePathname();
  const links = (data.view.sidebar ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${pathname}/${item.slug}`,
      caption: item.caption,
      target: '_self',
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${pathname}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  useEffect(() => {
    setIsLoaded(true);
  }, []);
  
  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={fsImages}
        types={fsImages.map(() => 'image')}
        sourceIndex={selectedImage}
      />

      <div className={`container mb-10 ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
        <div className="flex flex-col md:flex-row gap-6">
          <SideBar className="relative hidden lg:block w-1/4 bg-white rounded-lg shadow-md pt-3 pb-2 flex-grow-0 self-start flex-shrink-0" list={links}/>

          <main className="flex-1">
            <h3 className="text-[28px] font-semibold text-gunmetal mb-8">{ data.view?.top_block_caption }</h3>
            {
              (viewMain.map((item: Record<string,any>) => {
                if(item.is_video === 'Evet') return (
                  <section key={item.id}>
                    <VideoList caption={item?.caption ?? ''} testimonials={item.testimonials} urlRoot={`${pathname}/${item.slug}`} />
                    <div className="block w-full text-center py-10">
                      <LoadingLink href={`${pathname}/${item.slug}`} className="flex items-center text-lg justify-center text-lava font-semibold mx-auto" title={`Doping Hafıza ${item.caption}`}>
                        {item.caption}
                        <DPIcon icon="arrow-circle-right" className="text-lava text-2xl ml-2" />
                      </LoadingLink>
                    </div>
                  </section>
                );

                return (
                  <section key={item.id}>
                    <ImageList caption={item?.caption ?? ''} testimonials={item.testimonials} lbSelect={selectModalImageId} />
                    <div className="block w-full text-center py-10">
                      <LoadingLink href={`${pathname}/${item.slug}`} className="flex items-center text-lg justify-center text-lava font-semibold mx-auto" title={`Doping Hafıza ${item.caption}`}>
                        {item.caption}
                        <DPIcon icon="arrow-circle-right" className="text-lava text-2xl ml-2" />
                      </LoadingLink>
                    </div>
                  </section>
                );
              }))
            }
          </main>
        </div>
      </div>
    </>
  );
}
