"use client";

import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import { Dialog, Transition } from "@headlessui/react";
import { linkUrl, purify } from "@/helpers";
import { Fragment, useState } from "react";
import DPIcon from "../DPIcon/DPIcon";
import "./css/EmployeeCard.scss";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function EmployeeCard({ data }: Record<string, any>) {
  const fallback_url = linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/ekibimiz/', 'noimg.jpeg');
  if(!data.image_url) data.image_url = fallback_url;

  const [ dialogActive, setDialogActive ] = useState(false);

  return (
    <div className="bg-white p-3 rounded-lg shadow-lg overflow-hidden">
      <div className={`overflow-hidden relative block ${data?.content ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={() => setDialogActive(true)}>
        <div className="relative pb-[66.7%]">
          <ImageWithFallback
            fill={true}
            loading="lazy"
            src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/ekibimiz/', data.image_url)} 
            fallback={fallback_url} 
            alt={data?.name ?? 'Doping Hafıza'}
            style={{ objectFit: 'cover', objectPosition: 'top' }}
            sizes='100% 100%'
          />
        </div>
        <div className="p-3 bg-charcoal text-white min-h-[90px] rounded-md mt-[10px]">
          <h4 className="font-bold text-sm text-center">
            { data?.name ?? '' }
          </h4>
          <p className="text-center text-[13px]">{ data?.title ?? '' }</p>
        </div>

        <Transition
          show={dialogActive}
          enter="transition duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <Dialog onClose={() => setDialogActive(false)} className="z-[9999] fixed left-0 top-0 w-full h-screen">
            <Dialog.Panel>
              <div className={`__video-dialog`} tabIndex={0}>
                <div className="video-dialog-backdrop" onClick={() => setDialogActive(false)}></div>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="transform scale-50"
                  enterTo="transform scale-100"
                  leave="ease-in duration-300"
                  leaveFrom="transform scale-100"
                  leaveTo="transform scale-50"
                >
                  <div className="px-6 pt-4 pb-6 bg-white rounded-xl shadow-xl w-11/12 max-w-[1200px] max-h-[90vh] overflow-auto z-[1]">
                    <div className="text-2xl text-end cursor-pointer mb-2" onClick={() => setDialogActive(false)}>
                      <DPIcon icon="close" />
                    </div>

                    <div className="flex gap-7 flex-wrap lg:flex-nowrap">
                      <div className="relative mb-3 w-full lg:w-1/3 flex-grow-0 flex-shrink-0">
                        <div className="relative pb-[66.7%]">
                          <ImageWithFallback
                            fill={true}
                            loading="lazy"
                            src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/ekibimiz/', data.image_url)} 
                            fallback={fallback_url} 
                            alt={data?.name ?? 'Doping Hafıza'}
                            style={{ objectFit: 'cover', objectPosition: 'top' }}
                            sizes='100% 100%'
                          />
                        </div>
                        <div className="flex justify-center items-center gap-1 mt-4">
                          {
                            (() => {
                              if(data?.twitter) {
                                return (
                                  <LoadingLink
                                    href={data.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-slate-400 hover:bg-lava transition-all duration-300 text-white rounded-xl w-10 h-10 flex items-center justify-center"
                                  >
                                    <DPIcon icon="twitter" />
                                  </LoadingLink>
                                );
                              }
                            })()
                          }
                          {
                            (() => {
                              if(data?.linkedin) {
                                return (
                                  <LoadingLink
                                    href={data.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-slate-400 hover:bg-lava transition-all duration-300 text-white rounded-xl w-10 h-10 flex items-center justify-center"
                                  >
                                    <DPIcon icon="linkedin" />
                                  </LoadingLink>
                                );
                              }
                            })()
                          }
                        </div>
                      </div>

                      <div className="relative flex-grow">
                        <div className="">
                          <h4 className="font-bold text-2xl sm:text-3xl mb-5">{ data?.name ?? '' }</h4>
                          <p className="text-lg sm:text-xl font-semibold">{ data?.title ?? '' }</p>
                        </div>
                        <div className="__employee-card-content mt-5" dangerouslySetInnerHTML={purify(data?.content ?? '')}></div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
