import DPIcon from "@/components/DPIcon/DPIcon";

export default function InfoBox({ value, content, icon }: {
  readonly value?: string,
  readonly content?: string,
  readonly icon?: string,
}) {
  return (
    <div className="px-5 py-3 bg-cyber-grape text-white rounded-xl flex justify-start gap-3 items-center min-w-[144px] select-none">
      { icon && (<DPIcon icon={icon} className="text-2xl" />) }
      <div className="flex flex-col items-start whitespace-nowrap">
        { value && (<span className="text-base font-bold">{ value }</span>) }
        { content && (<span className="text-[9px]">{ content }</span>) }
      </div>
    </div>
  );
}