"use client";

import MostPreferredPackages from "@/components/MostPreferredPackages/MostPreferredPackages";
import { PackageListData } from "@/types/page";

export default function OurPackages({ packageData }: {
  readonly packageData: PackageListData,
}) {
  
  return (
    <>
      {/*
      When rowsPerPage is -1, it ensures that
      the component lists every package
      instead of showing a "load more" button 
      */}
      <MostPreferredPackages packageData={packageData} rowsPerPage={-1} noneSwiping={true} gtmListId="package_list" />
    </>
  );
}
