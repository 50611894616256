"use client";
import Image from "next/image";
import AutoComplete, { ACOption } from "./Autocomplate";
import { useEffect, useState } from "react";
import DPIcon from "@/components/DPIcon/DPIcon";
import { linkUrl, purify } from "@/helpers";
import { usePathname } from "next/navigation";
import InfoCarousel from "@/components/InfoCarousel/InfoCarousel";
import InfoItem from "@/components/InfoCarousel/InfoItem";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import _e from "@/helpers/texts";
import { formatUrl } from "@/helpers/formatters";
import StartLiveChat from "@/components/StartLiveChat/StartLiveChat";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export default function FaqMain({ data, list }: {
  data: Record<string,any>
  list: Record<string,any>[]
}) {
  const view = data.view;
  const root = usePathname();

  const [selected, setSelected] = useState<string>("");
  const [isMobile, setIsMobile] = useState<boolean>(true);

  view.faq_cats.forEach((item: Record<string,any>) => {
    item.linkable = item.faqs.filter((faq: Record<string,any>) => faq.in_menu);
  });

  useEffect(() => {
    try {
      if(window) setIsMobile(window?.innerWidth > 1024);
    } catch(e) {}
  }, []);

  return (
    <div className="bg-cultured">
      <section>
        <div
          className="mt-0 lg:mt-3 relative h-screen md:h-[500px] before:hidden after:hidden before:absolute before:content-[''] lg:before:block before:w-[420px] before:h-[120px] before:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')] before:z-10 before:bg-left-bottom before:bottom-[-41px] before:left-0
    after:absolute after:content-[''] lg:after:block after:w-[420px] after:h-[120px] after:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')] after:bg-right-top after:top-[-58px] after:right-0"
        >
          <Image
            src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/', view?.hero_image_url ?? "") ?? ''}
            alt={"Image"}
            fill={true}
            sizes="100%"
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            loading="lazy" //! priority={true}
          />
          <div className="absolute  left-1/2 top-1/2 -translate-y-1/2  -translate-x-1/2 w-full z-10">
            <h1 className="text-left md:text-center font-bold text-white mb-[50px] text-[30px] md:text-[40px] pl-8 md:pl-0 leading-tight" dangerouslySetInnerHTML={purify(view?.hero_caption ?? '')}></h1>

            <div className="search relative max-w-[360px] sm:max-w-[777px] md:max-w-[500px] lg:max-w-[777px] w-full text-center m-auto ">
              <AutoComplete
                placeholder={isMobile ? "Yardım Makalelerinde Ara..." : "Ara..."}
                options={list as ACOption[]}
                value={selected}
                root={root}
                onChange={(val) => setSelected(val)}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-[48px] relative">
        <div className="w-full mx-auto">
          <h3 className="text-center text-[#212529] text-[28px] font-semibold">
            { view?.heading }
          </h3>
          <p className="text-center text-[#2125xw29] mt-[8x] mb-[50px]">
            { view?.sub_heading }
          </p>
          <div className="mb-[100px]">
            <InfoCarousel defaultItem={0}>
              {
                view.main_faqs?.map((item: Record<string,any>) => (
                  <InfoItem key={item.id} title={item.caption} className="mt-5">
                    {
                      item?.video_url && item?.video_placement === "top" && (
                        <div>
                          <VideoPlayer src={item.video_url} poster={item?.video_poster_url} />
                        </div>
                      )
                    }
                    
                    <div className={`
                      text-sm leading-7 [&>h1]:text-xl [&>h2]:text-xl [&>h3]:text-xl [&>h4]:text-xl
                      [&>h1]:pb-4 [&>h2]:pb-4 [&>h3]:mb-4 [&>h4]:pb-4 [&>p]:pb-4 
                    `} dangerouslySetInnerHTML={purify(item.content)}></div>

                    {
                      item?.video_url && item?.video_placement === "bottom" && (
                        <div className="relative">
                          <VideoPlayer src={item.video_url} poster={item?.video_poster_url} />
                          <div><br/><br/></div>
                        </div>
                      )
                    }
                  </InfoItem>
                ))
              }
            </InfoCarousel>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h3 className="text-center text-[#212529] text-[28px] mb-[10px] font-bold">
            { view.subject }
          </h3>
          <p className="text-center text-[#2125xw29]  mb-[70px]">
            { view.sub_subject }
          </p>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-10 help ">
            {
              view.faq_cats?.map((item: Record<string,any>) => (
                <div key={item.id}>
                  <DPIcon icon={item?.dp_icon} className="text-[70px] text-lava" />
                  <h4 className="text-[21px] font-bold mb-[25px] mt-[40px]">
                    { item.name }
                  </h4>
                  <div className="flex flex-col lg:min-h-[310px] space-y-4 justify-between">
                    <ul>
                      {
                        item.linkable.slice(0, 4).map((faq: Record<string,any>) => (
                          <li key={faq.id} className="mb-[25px]">
                            <LoadingLink href={`${root}/${item.slug}/${faq.slug}`}>{faq.caption}</LoadingLink>
                          </li>
                        ))
                      }
                    </ul>
                    <LoadingLink href={`${root}/${item.slug}`} className="flex items-center navlink text-lava">
                      <span className="font-semibold pr-[5px]">{ _e('Tümünü Göster') }</span>
                      <DPIcon icon="arrow-circle-right" />
                    </LoadingLink>
                  </div>
                </div>
              ))
            }

            <div>
              <DPIcon icon={view.resources_icon} className="text-[70px] text-lava" />
              <h4 className="text-[21px] font-bold mb-[25px] mt-[40px]">
                { view.resources_cap }
              </h4>
              <div className="flex flex-col lg:min-h-[310px] space-y-4 justify-between">
                <ul>
                  {
                    view.resources.slice(0, 4).map((item: Record<string,any>) => (
                      <li key={item.id} className="mb-[25px]">
                        <LoadingLink href={formatUrl(item.slug, true)}>{ item.caption }</LoadingLink>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-20 mb-20">
            <StartLiveChat />
          </div>
        </div>
      </section>
    </div>
  );
}
