"use client";
import { useRef } from "react";
import "./css/ilkokul.scss";
import CommonButton from "./CommonButton";
import Image from "next/image";
import _e from "@/helpers/texts";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { purify, stripTags } from "@/helpers";

export default function Hero({ text, video_src, video_poster}: {
  readonly text: string,
  readonly video_src: string,
  readonly video_poster?: string,
}) {
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const handleHeroButton = () => {
    const block = document?.querySelector('#products') as HTMLDivElement;
    if(block) {
      try {
        window?.scrollTo({ top: block.offsetTop + (window?.innerWidth > 1024 ? 56 : 0), behavior: 'smooth' });
      } catch(e) {}
    }
  };

  return (
    <div className="relative">
      <Image
        src={"/assets/images/ilkokul/neler-var.png"}
        alt={"Doping Hafızada Neler Var"}
        loading="lazy" //! priority={true}
        width={269}
        height={259}
        className="absolute select-none z-[5] -bottom-[140px] -right-[115px] lg:hidden"
      />

      <Image
        src={"/assets/images/ilkokul/hero-bg.png"}
        alt={'Doping Hafıza'}
        fill={true}
        loading="lazy" //! priority={true}
        sizes="100%"
        style={{ objectFit: 'cover', objectPosition: 'center' }}
      />

      <div className="relative container z-10">
        <div className="py-[17px] lg:py-[52px] flex flex-col lg:flex-row justify-between items-stretch">
          <div className="w-[40%] text-center hidden lg:flex flex-col justify-center items-center px-4">
            <h1 className="text-[#F0ECFF] text-[32px] font-medium leading-tight" dangerouslySetInnerHTML={purify(text)} />
            <CommonButton color="orange" width="100%" className="mt-[32px] mx-auto !max-w-[350px]" onClick={handleHeroButton}>
              { _e('Paketleri İncele') }
            </CommonButton>
          </div>

          <div className="w-[100%] pb-[20px] text-center flex lg:hidden flex-col justify-center items-center px-4">
            <h1 className="text-[#F0ECFF] text-[20px] font-medium leading-tight">{stripTags(text)}</h1>
          </div>

          <div className="w-full lg:w-[55%] ms-0 lg:ms-[5%] mt-[10px] lg:mt-0 flex justify-center items-center">
            <div className="relative w-full">
              <Image
                src={"/assets/images/ilkokul/hero-video-poster.png"}
                alt={'Doping Hafıza'}
                fill={true}
                loading="lazy" //! priority={true}
                sizes="100%"
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />

              <div ref={videoContainerRef} className={`relative z-10 transition-all opacity-0 [&.opacity-0>.plyr>button]:w-[128px] [&.opacity-0>.plyr>button]:h-[128px] w-full [&>div.plyr]:w-full [&>div.plyr]:aspect-video`}>
                <VideoPlayer 
                  src={video_src}
                  poster={video_poster ? video_poster : "/assets/images/ilkokul/hero-video-poster.png"}
                  onInteraction={(id: string, type: string) => {
                    if(type === 'play') {
                      setTimeout(() => {
                        videoContainerRef?.current?.classList.remove('opacity-0');
                      }, 200);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-[100%] pb-[10px] text-center flex lg:hidden flex-col justify-center items-center">
            <CommonButton color="orange" width="100%" className="mt-[32px] mx-auto !max-w-[350px]" onClick={handleHeroButton}>
              { _e('Paketleri İncele') }
            </CommonButton>
          </div>

        </div>
      </div>
    </div>
  );
}
