"use client";

import { Children, forwardRef, useCallback, useEffect, useId, useImperativeHandle, useRef, useState } from "react";
import InfoItem from "./InfoItem";

const InfoCarousel = forwardRef(function InfoCarousel({ className, trackDetailRequest, children, defaultItem, size }: {
  readonly className?: string,
  readonly trackDetailRequest?: boolean,
  readonly children: React.ReactElement|React.ReactElement[],
  readonly defaultItem?: number,
  readonly size?: 'thick' | 'thin',
}, ref) {
  
  const [ activeItem, setActiveItem ] = useState<string|number|undefined>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>();

  const tabId = useId();
  const activeRef = useRef<string|number|undefined>();

  const toggle = useCallback((id: number|string, forceOpen?: boolean) => {
    activeRef.current = id;
    if(activeItem === id) activeRef.current = forceOpen ? id : undefined;

    if(activeRef.current !== activeItem) {
      setActiveItem(activeRef.current);
    }

    return activeRef.current;
  }, [ activeItem ]);

  useImperativeHandle(ref, () => {
    return {
      getActiveIndex: () => {
        return activeRef.current?.toString().split(tabId)[1] ?? undefined;
      },
      getActiveId: () => {
        return activeRef.current?.toString() ?? undefined;
      },
    };
  });

  useEffect(() => {
    if(isLoaded === undefined && defaultItem !== undefined && activeItem === undefined) {
      toggle(tabId + defaultItem.toString(), true);
    }
    setIsLoaded(true);

    const detailRequested = (event: CustomEvent) => {
      if(trackDetailRequest) {
        setTimeout(() => {
          toggle(tabId + event.detail.index.toString(), true);
        }, 100);
      }
    };
  
    window?.addEventListener('detailRequest', detailRequested as EventListener);
    return () => {
      window?.removeEventListener('detailRequest', detailRequested as EventListener);
    };
  }, [
    tabId,
    trackDetailRequest,
    activeItem,
    defaultItem,
    isLoaded,
    toggle
  ]);

  return (
    <div className={className ?? ''}>
      {
        Children.map(children, (child: React.ReactElement, index: number) => {
          if(child?.props?.title) return (
            <InfoItem
              id={tabId + index.toString()}
              title={child.props.title}
              toggler={toggle}
              toggleState={activeItem}
              className={child.props.className ?? ''}
              size={child.props.size ?? size ?? 'thick'}
              image={child.props.image}
              icon={child.props.icon}
              onOpen={child.props?.onOpen}
              onClose={child.props?.onClose}
              onToggle={child.props?.onToggle}
            >
              { Children.map(child.props.children, (sub: any) => ( sub )) }
            </InfoItem>
          );
        })
      }
    </div>
  );
});

export default InfoCarousel;