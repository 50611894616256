"use client";

import LoadingLink from "@/components/LoadingLink/LoadingLink";
import _e from "@/helpers/texts";
import { BsCalculator } from 'react-icons/bs';

export default function ScoreMain({ data }: Record<string, any>) {
  return (
    <div className="relative">
      <h1 className='text-3xl mb-8 grid self-center text-charcoal font-medium'>
        { data.view.caption }<br/>
        <strong className='text-lava text-3xl'>{ data.view.subcap }</strong>
      </h1>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
        {
          data.links?.map((link: Record<string, any>) => (
            <div key={link.id} className='rounded-md border-8 border-white'>
              <LoadingLink href={link.url}>
                <div
                  className={`grid justify-center justify-items-center rounded-md py-8 px-4 transition ease-in-out delay-40 md:hover:-translate-y-1 md:hover:scale-110 duration-300 cursor-pointer`}
                  style={{ backgroundColor: link.bg_color}}
                >
                  <BsCalculator size={80} color='white' />
                  
                  <div className='text-center text-white text-3xl font-bold pt-4'>
                    {link.type}
                    <div className='text-xl font-normal'>{ _e('Puan Hesaplama') }</div>
                  </div>
                </div>
              </LoadingLink>
            </div>
          ))
        }
      </div>
    </div>
  );
}
