"use client";

import { useEffect, useState } from "react";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import { linkUrl, purify } from "@/helpers";
import { formatDate, formatUrl } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import ArticleCard from "../ArticleCard/ArticleCard";
import styles from "./css/newsFormUs.module.scss";
import "./css/articleContent.scss";
import { usePathname } from "next/navigation";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function Article({
  data,
  root,
  imgRoot,
  packagesLink,
}: {
  readonly data: Record<string, any>;
  readonly packagesLink?: Record<string, any>;
  readonly root: string;
  readonly imgRoot?: string;
}) {
  const fallback_url = "https://assets-dopinghafiza.dopingtech.net/assets/files/bizden-haberler/1(3).jpg";
  const imgDir = imgRoot ?? "blog";
  const pathname = usePathname();

  if (!data?.articles?.length) {
    const articles = data?.blog?.articles ?? [];
    articles.sort((a: Record<string, any>, b: Record<string, any>) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
    data.articles =
      articles
        .filter((article: Record<string, any>) => article.id !== data.id)
        ?.slice(0, 3) ?? [];
  }

  if(data?.articles?.length) {
    const path = pathname.split("/");
    path.pop();
    data.articles.forEach((article: Record<string, any>) => {
      switch(path?.length) {
      case 2:
        article.link = formatUrl(`/${path[1]}/${article.slug}`, true);
        break;
      case 3:
        article.link = formatUrl(`/${path[1]}/${article.blog.slug}/${article.slug}`, true);
        break;
      default:
        article.link = formatUrl(`${root ?? ""}/${article.blog.slug}/${article.slug}`, true);
      }
    });
  }

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div
      className={
        isLoaded ? "opacity-100 transition-all duration-300" : "opacity-0"
      }
    >
      <div
        className={`min-h-[190px] lg:min-h-[500px] h-full relative mb-[25px]
        w-full before:hidden after:hidden before:absolute before:content-[''] lg:before:block
        before:w-[420px] before:h-[120px]
        before:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')]
        before:z-10 before:bg-left-bottom before:bottom-[-41px] before:left-0
        after:absolute after:content-[''] lg:after:block after:w-[420px] after:h-[120px]
        after:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')]
        after:bg-right-top after:top-[-58px] after:right-0 block lg:hidden`}
      >
        <ImageWithFallback
          fill={true}
          src={linkUrl(
            process.env.NEXT_PUBLIC_ASSET_ROOT +
              "/assets/files/" +
              imgDir +
              "/",
            data?.image_url ?? ""
          )}
          fallback={fallback_url}
          alt={data?.caption ?? "Doping Hafıza"}
          className="object-cover"
          loading="lazy" //! priority={true}
        />
      </div>
      <div className="container mt-[24px] lg:mt-10 mb-16">
        <div
          className={`min-h-[190px] lg:min-h-[500px] h-full relative mb-[25px]
          w-full before:hidden after:hidden before:absolute before:content-[''] lg:before:block
          before:w-[420px] before:h-[120px]
          before:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')]
          before:z-10 before:bg-left-bottom before:bottom-[-41px] before:left-0
          after:absolute after:content-[''] lg:after:block after:w-[420px] after:h-[120px]
          after:bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/global/footer-badge.svg')]
          after:bg-right-top after:top-[-58px] after:right-0 hidden lg:block`}
        >
          <ImageWithFallback
            fill={true}
            src={linkUrl(
              process.env.NEXT_PUBLIC_ASSET_ROOT +
                "/assets/files/" +
                imgDir +
                "/",
              data?.image_url ?? ""
            )}
            fallback={fallback_url}
            alt={data?.caption ?? "Doping Hafıza"}
            className="object-cover"
            loading="lazy" //! priority={true}
          />
        </div>
        <div className="p-5 lg:p-10 bg-white shadow-default rounded-[8px]">
          <h1 className="text-gunmetal font-semibold leading-10 text-[28px] mb-2">
            {data?.caption}
          </h1>
          <p className="text-honolulu-blue font-semibold mb-12">
            {formatDate(data?.date)}
          </p>
          <div
            className="text-sm leading-7 __article-page-content-container"
            dangerouslySetInnerHTML={purify(data?.content ?? "")}
          ></div>
        </div>
        {data?.blog?.use_packages_link && packagesLink ? (
          <div className="bg-honolulu-blue p-[30px] flex items-center justify-center text-center flex-col gap-y-[46px] mt-[48px]">
            <h4 className="text-amber text-[20px] font-semibold">
              Eğitim Paketlerimizin İçerikleri ve Fiyatları Hakkında Detaylı
              Bilgi için
            </h4>
            <button className="bg-lava hover:bg-white text-white px-[25px] py-[15px] rounded-[8px] hover:text-lava duration-300 scale-100 hover:scale-105">
              <LoadingLink href={`/${packagesLink.root}`}>TIKLAYIN</LoadingLink>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {data?.articles?.length ? (
        <div
          className={`${styles.clipPath} relative z-10 py-[60px] before:absolute before:content-[''] before:block before:w-full before:h-full before:top-0 before:left-0 before:bg-white`}
        >
          <div className="container relative z-10">
            <h2 className="text-3xl leading-[50px] font-semibold">
              {_e("İlginizi çekebilecek")}
            </h2>
            <p className="text-[40px] mb-5 font-bold leading-[60px] text-lava">
              {_e("benzer blog içerikleri")}
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-[30px] ">
              {data?.articles?.map((article: Record<string, any>) => (
                <ArticleCard
                  key={article.id}
                  image={article?.image_url ?? "-"}
                  link={article?.link}
                  caption={article.caption}
                  category={article?.blog?.name ?? "Doping Hafıza"}
                  date={article.date}
                  className="mb-8"
                  imgRoot={imgDir}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
