"use client";
import ArticleCard from "@/components/ArticleCard/ArticleCard";
import { formatUrl } from "@/helpers/formatters";

export default function BlogMain({ data }: {
  data: Record<string, any>,
}) {
  const articles = data?.view?.articles ?? [];
  
  /*
  If we want to sort by date instead of user sort
  unmark below
  
  articles.sort((a: Record<string, any>, b: Record<string, any>) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
  /* -o- */

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[30px] gap-y-[24px]">
        {
          articles?.map((article: Record<string, any>) => (
            <ArticleCard key={article.id}
              image={article?.image_url ?? '-'}
              link={formatUrl(`${data.slug}/${article.blog.slug}/${article.slug}`, true)}
              caption={article.caption}
              category={article.blog.name}
              date={article.date}
            />
          ))
        }
      </div>
    </>
  );
}
