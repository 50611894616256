"use client";

import VideoList from "./VideoList";
import { LinkType } from "@/components/FastMenu/FastMenu";
import _e from "@/helpers/texts";
import SideBar from "@/components/SideBar/SideBar";

export default function SampleVideoSubCategory({ data }: {
  data: Record<string, any>,
}) {
  const links = (data.allCategories ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${data.videoRoot}/${item.slug}`,
      caption: item.caption,
      target: '_self',
      sublink: item?.video_sub_categories?.length > 1 ? item.video_sub_categories.map((sub: Record<string,any>) => {
        return {
          id: sub.id,
          url: `${data.videoRoot}/${item.slug}/${sub.slug}`,
          caption: sub.caption,
          target: 'self',
        };
      }) : undefined,
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${data.videoRoot}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  return (
    <div className="container">
      <div className="grid grid-cols-1 lg:grid-cols-11 gap-x-[30px] mb-10">
        <SideBar className="bg-white shadow-default col-span-3 h-fit rounded-[6px] hidden lg:block" list={links} />

        <VideoList caption={data?.caption} videos={data?.videos ?? []} />
      </div>
    </div>
  );
}
