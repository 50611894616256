"use client";

import { LinkType } from '@/components/FastMenu/FastMenu';
import PackageUsageInfo from '@/components/PackageUsageInfo/PackageUsageInfo';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { linkUrl, purify } from '@/helpers';
import _e from '@/helpers/texts';
import './css/VideoDetail.scss';
import {useEffect, useState} from 'react';
import JsonLd from '@/components/JsonLd/JsonLd';
import SideBar from '@/components/SideBar/SideBar';

export default function VideoDetailMain({ data, root }: {
  data: Record<string, any>,
  root: string,
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const links = (data.allCategories ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${root}/${item.slug}`,
      caption: item.caption,
      target: '_self',
      sublink: item?.video_sub_categories?.length > 1 ? item.video_sub_categories.map((sub: Record<string,any>) => {
        return {
          id: sub.id,
          url: `${root}/${item.slug}/${sub.slug}`,
          caption: sub.caption,
          target: 'self',
        };
      }) : undefined,
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${root}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  return (
    <>
      <div className={`container mb-10 ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
        <div className="flex flex-col md:flex-row gap-6 md:items-start">
          <SideBar className="bg-white shadow-default w-1/4 rounded-[6px] hidden lg:block" list={links} />

          <main className="flex-1">

            <div className="col-span-8 bg-white p-5 lg:p-10 rounded-[6px] mb-10">
              <h2 className="text-[#1d252d] font-semibold text-[28px] leading-10 mb-[30px]">
                <span className="">{ data?.caption ?? '' }</span>
              </h2>

              {
                data?.genial ? (
                  <div className="relative before:w-full before:pb-[56.25%] before:block">
                    <iframe src={`https://view.genial.ly/${data.genial}`} allow="autoplay; fullscreen; clipboard-read; clipboard-write; encrypted-media; geolocation; microphone" style={{ 'position': 'absolute', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%' }}></iframe>
                  </div>
                ) : (
                  <VideoPlayer
                    src={linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.video_url) ?? ''}
                    poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data?.video_poster_url) ?? ''}
                  />
                )
              }
              <JsonLd data={{
                "@context": "https://schema.org",
                "@type": "VideoObject",
                "name": data.caption,
                "description": data.subcap,
                "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data?.video_poster_url) ?? '',
                "uploadDate": data.publishedAt,
                "duration": data?.duration ? 'PT' + Math.floor((data.duration ?? 0) / 60) + 'M' + ((data.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
                "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.video_url) ?? '',
              }} />
              
              <div className="text-white bg-cornflower-blue shadow-[0_8_24_rgba(11,106,178,0.1)] rounded-[6px] py-5 px-[10px] my-10">
                <span>{ data?.subcap ?? data?.caption ?? '' }</span>
              </div>

              {
                data?.content && (
                  <div className="mb-10 __video-detail-page-content-container" dangerouslySetInnerHTML={purify(data.content)}></div>
                )
              }

              { data.package?.id && (
                <PackageUsageInfo
                  data={data.package}
                  packagesSlug={data.packages_slug}
                />
              ) }
            </div>

          </main>
        </div>
      </div>
    </>
  );
}
