"use client";

import JsonLd from "@/components/JsonLd/JsonLd";
import LoadingLink from "@/components/LoadingLink/LoadingLink";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";

export default function VideoList({ caption, testimonials, urlRoot }: {
  caption: string,
  testimonials: Record<string, any>[],
  urlRoot?: string,
}) {

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
      {testimonials.map((testimonial: Record<string,any>) => (
        <div className="bg-white py-3 px-4" key={testimonial.id}>
          <div>
            <VideoPlayer
              src={testimonial.video_url}
              poster={testimonial?.video_poster_url ?? ''}
            />
          </div>
          <div>
            {
              (() => {
                if(testimonial?.slug && urlRoot) {
                  return (
                    <LoadingLink href={`${urlRoot}/${testimonial.slug}`}
                      className="flex w-full mt-3 hover:text-lava text-sm font-semibold pb-3 mb-3 border-b-[1px] border-b-slate-300"
                    >
                      {testimonial?.name ?? testimonial?.caption ?? caption}
                    </LoadingLink>
                  );
                }

                return (
                  <span
                    className="flex w-full mt-3 text-sm font-semibold pb-3 mb-3 border-b-[1px] border-b-slate-300"
                  >
                    {testimonial?.name ?? testimonial?.caption ?? caption}
                  </span>
                );
              })()
            }
            <div className="text-sm text-right">{testimonial?.duration ? Math.floor((testimonial.duration ?? 0) / 60) + ':' + ((testimonial.duration ?? 0) % 60).toString().padStart(2, '0') : '01:00'}</div>
          </div>
        </div>
      ))}

      <JsonLd data={{
        "@context": "https://schema.org",
        "@type": "VideoGallery",
        "itemListElement": testimonials?.map((item: Record<string,any>, index: number) => {
          return {
            "@type": "VideoObject",
            "position": index + 1,
            "name": item?.name ?? item?.content ?? item?.caption,
            "description": item?.content,
            "thumbnailUrl":item.video_poster_url ?? '',
            "duration": item?.duration ? 'PT' + Math.floor((item.duration ?? 0) / 60) + 'M' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
            "contentUrl": item.video_url,
            "uploadDate": item.publishedAt,
          };
        })
      }} />

    </div>
  );
}
