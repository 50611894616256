"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { gtmSender, purify } from "@/helpers";
import { formatNumber } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useEffect, useRef, useState } from "react";
import { addToCart } from "@/components/CartLink/CartLink";
import { usePathname, useRouter } from "next/navigation";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export type FaqItem = {
  id: number,
  title: string,
  content: string
};
export type CustomDopiverseMainProps = {
  dopiList: { id: number, title: string }[],
  dopiSlider: {
    id: number,
    title: string,
    content: string,
    bg: string,
    image: {
      id: number,
      src: string,
      width: number,
      height: number,
      absolute?: boolean
    }[],
    mobileImage: {
      src: string,
      width: number,
      height: number
    },
  }[],
  learnventure: {
    title: string,
    content: string,
    images: {
      id: number,
      image: string,
      width: number,
      height: number
    }[],
  },
  dopiMore: {
    title: string,
    content: string,
    images: {
      id: number,
      caption: string,
      content: string,
      image: string,
      width: number,
      height: number
    }[],
  },
  dopiFaq: FaqItem[],
  references: {
    id: number,
    image: string,
    width: number,
    height: number,
    link?: string,
  }[],
  floatingImages: { id: number, width: number, height: number, top: number, right?: number, left?: number, yRatio: number, image: string }[],
  price: number,
  oldPrice: number,
};

export default function CustomDopiverseMain({ data }: {
  readonly data: CustomDopiverseMainProps & Record<string,any>,
}) {
  const router = useRouter();
  const pathname = usePathname();
  const pathRef = useRef<string>();
  
  const { dopiList, dopiSlider, learnventure, dopiMore, dopiFaq, references, floatingImages, price, oldPrice } = data;
  const [ scrollTop, setScrollTop ] = useState<number>(0);
  const [ faqActive, setFaqActive ] = useState<number>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="!bg-white !rounded-full !transition-all !duration-300 !w-[6px] !h-[6px] !opacity-50 ' + className + '" data-index="' + index + '"></span>';
    },
  };

  const faqItems = useRef<any>([]);
  faqItems.current = [];

  const toggleFaq = (id: number) => {
    let active: number|undefined = undefined;

    dopiFaq.forEach((item) => {
      if(item.id === id) active = faqActive !== id ? id : undefined;
    });

    setFaqActive(active);
  };

  useEffect(() => {
    if(pathRef.current !== pathname) {
      const eventVal: Record<string,any> = {
        "event": "view_item",
        "ecommerce": {
          "currency": "TRY",
          "items": [
            {
              "id": data?.ecommerce_id,
              "name": 'Dopiverse',
              "price": data?.price,
              "brand": 'Doping Hafıza',
              "category": data?.attributes?.caption ?? '',
            }
          ]
        }
      };

      gtmSender({ ecommerce: null });
      gtmSender(eventVal);
      pathRef.current = pathname;
    }

    const handleResize = () => {
      faqItems.current.forEach((item: any) => {
        if(item.container && item.title && item.content) {
          item.container.style.height = item.title.offsetHeight + (item.content.offsetHeight * (item.id === faqActive ? 1 : 0)) + 'px';
        }
      });
    };
    
    handleResize();

    const handleScroll = () => {
      setScrollTop(window?.scrollY ?? 0);
    };
    handleScroll();
    
    setIsLoaded(true);
    
    window?.addEventListener('scroll', handleScroll);
    window?.addEventListener('resize', handleResize);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
      window?.removeEventListener('resize', handleResize);
    };
  }, [
    faqActive,
    data?.ecommerce_id,
    data?.price,
    data?.attributes?.caption,
    pathname,
  ]);

  return (
    <main
      className={`
        relative pt-24 bg-no-repeat bg-left-top bg-cover overflow-hidden
        bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/landing/dopiverse-bg.jpg')]
        ${ isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0' }
      `}
    >
      {/* Floating images */}
      {
        floatingImages.map((item) => (
          <div
            key={item.id}
            className="absolute"
            style={{
              width: item.width + 'px',
              height: item.height + 'px',
              top: item.top + 'px',
              right: item?.right ? item.right + 'px' : 'auto',
              left: item?.left ? item.left + 'px' : 'auto',
              transform: `translateY(${item.yRatio * scrollTop * .01}%)`
            }}
          >
            <Image
              src={item.image}
              alt="Doping Hafıza"
              width={item.width}
              height={item.height}
              loading="lazy" //! priority={true}
            />
          </div>
        ))
      }

      {/* Meet Dopiverse */}
      <section className="">
        <div className="container">
          <div className="flex flex-wrap text-white -mx-3">
            <div className="relative flex flex-col w-full lg:w-1/2 p-3">
              <h1 className="text-5xl mb-2 leading-tight font-black tracking-wide">Dopiverse ile Tanışın!</h1>
              <div className="flex flex-col items-stretch gap-1 text-sm font-medium">
                {
                  dopiList.map((item) => (
                    <div className="flex justify-start gap-1 mt-1" key={item.id}>
                      <div className="flex-grow-0">🪀</div>
                      <span className="flex-grow" dangerouslySetInnerHTML={purify(item.title)}></span>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="relative p-3 w-full lg:w-1/2 mt-7 lg:mt-0">
              <div className={`
                overflow-hidden rounded-ss-[40px] rounded-se-[10px] rounded-es-[10px] rounded-ee-[40px]
                [&>*>button]:!bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/landing/dopiverse-player-btn.svg')]
                [&>*>button]:!bg-no-repeat
                [&>*>button]:!bg-contain
                [&>*>button]:!bg-transparent
                [&>*>button]:!w-[125px]
                [&>*>button]:!h-[125px]
                [&>*>button>svg]:!hidden
              `}>
                <VideoPlayer
                  src="https://media.dopinghafiza.com/ornek-videolar/tanitim/dopiverse-v2/media.m3u8"
                  poster="https://assets-dopinghafiza.dopingtech.net/assets/images/landing/dopiverse-teaser.jpg"
                />
              </div>
            </div>
              
            <div className="relative hidden sm:block w-full lg:w-1/2 p-3">
              <div className="flex flex-row items-center gap-3 px-6 py-4 mt-10 rounded-[18px] bg-white">
                <div className="flex flex-col items-start">
                  <div className="flex justify-start gap-3 items-baseline">
                    <span className="text-honolulu-blue text-[26px] xl:text-[34px] font-bold">{ formatNumber(price) }&nbsp;TL</span>
                    {
                      true && (
                        <span className="text-lava text-lg font-semibold line-through">{ formatNumber(oldPrice) }&nbsp;TL</span>
                      )
                    }
                  </div>
                  <div className="text-price-success text-sm xl:text-base">
                    <DPIcon icon="warning" className="me-1" />
                    Dopiverse&rsquo;e 1 yıllık abonelik (Tablet - Mobil)
                  </div>
                </div>
                
                <div className="flex justify-end flex-grow">
                  <button className="btn btn-blue whitespace-nowrap w-full md:w-auto" data-ecommerce-id={data.ecommerce_id} onClick={() => addToCart(data.external_id, data.ecommerce_id, price, 'Dopiverse', data?.attributes?.caption ?? '', router)}>
                    { _e('Hemen satın al') }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Reference Icons */}
      <section className="mt-20">
        <div className="container">
          <div className="flex justify-center lg:justify-between items-center flex-wrap bg-white p-10 rounded-3xl">
            {
              references.map((item) => {
                if(item?.link) return (
                  <LoadingLink key={item.id} href={item.link} target="_blank" rel="noopener noreferrer" className="w-1/2 lg:w-[210px] xl:w-[235px] my-2 xl:my-0 flex justify-center">
                    <Image
                      src={item.image}
                      alt="Doping Hafıza"
                      width={item.width}
                      height={item.height}
                      loading="lazy" //! priority={true}
                    />
                  </LoadingLink>
                );

                return (
                  <div key={item.id} className="w-1/2 px-4 min-[420px]:px-6 lg:px-0 lg:w-[210px] xl:w-[235px] my-2 xl:my-0 flex justify-center">
                    <Image
                      src={item.image}
                      alt="Doping Hafıza"
                      width={item.width}
                      height={item.height}
                      loading="lazy" //! priority={true}
                    />
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>

      {/* Safe Virtual Universe */}
      <section className="mt-24 md:mt-48">
        <div className="container">
          <div className="relative rounded-[40px] py-[60px] px-[30px] sm:px-[60px] lg:py-[75px] lg:px-[90px] bg-gradient-to-b from-[rgba(69,255,244,.5)] to-[rgba(112,0,255,.2)]">
            <Image
              src="https://assets-dopinghafiza.dopingtech.net/assets/images/landing/guvenli-bir-evren.png"
              alt="Doping Hafıza"
              width={283}
              height={384}
              className="absolute hidden sm:block w-[200px] h-auto lg:w-auto lg:h-auto -right-[45px] -top-[72px] lg:-right-[80px] lg:-top-[100px]"
            />
            <Image
              src="https://assets-dopinghafiza.dopingtech.net/assets/images/landing/world.svg"
              alt="Doping Hafıza"
              width={166}
              height={161}
              className="absolute w-[120px] h-auto lg:w-auto lg:h-auto -left-[57px] -top-[54px] lg:-left-[83px] lg:-top-[80px]"
            />

            <div className="text-white leading-loose">
              <h1 className="text-3xl sm:text-4xl lg:text-5xl leading-tight font-black tracking-wide mb-4">
                Güvenli Bir<br/>
                Sanal Evren
              </h1>
              <p>
                ABD’de çocukların çevrimiçi güvenliği ve gizliliğini koruma kanununun (COPPA) gerekliliklerini
                denetleyen <b>KidSAFE</b> ve 60’tan fazla ülkede geçerliliği olan <b>Mom’s Choice Award</b>
                (Annelerin Seçimi Ödülü) gibi dünya çapında güvenilirliğini kanıtlamış
                kurumlar tarafından verilen sertifikalara sahiptir.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Dopiverse Learnventure */}
      <section className="mt-24 py-20 bg-[#1C77A8]">
        <div className="container text-white text-center">
          <h2 className="text-[28px] lg:text-[40px] leading-tight font-bold mb-2" dangerouslySetInnerHTML={purify(learnventure.title)}></h2>
          <p className="text-sm leading-loose" dangerouslySetInnerHTML={purify(learnventure.content)}></p>

          <div className="flex justify-center flex-wrap mt-10 -mx-3">
            {
              learnventure.images.map((item) => (
                <div key={item.id} className="w-full sm:w-1/2 md:w-1/3 p-3">
                  <div className="relative w-full">
                    <Image
                      src={item.image}
                      alt="Doping Hafıza"
                      width={item.width}
                      height={item.height}
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      {/* Dopiverse slider */}
      <section className="mt-36">
        <div className="container text-white">
          <h1 className="text-[28px] sm:text-3xl lg:text-5xl leading-tight tracking-wide font-black mb-5 text-center">Dopiverse Evreninde Sizi<br />Neler Bekliyor?</h1>
          
          <div className="relative">
            {/* Navigation buttons */}
            <div className="absolute top-20 bottom-0 left-0 right-0 z-0 hidden min-[1320px]:flex justify-between lg:-mx-[80px] min-[1520px]:-mx-[180px] overflow-visible">
              <div
                className={`
                  swiper-button-prev-dopiverse
                  cursor-pointer
                  bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/landing/dopiverse-arrow.png')]
                  bg-[center_top_22px]
                  bg-contain
                  bg-no-repeat
                  w-[90px] h-[90px]
                  absolute
                  left-0
                  top-[50%]
                  -translate-y-[120%]
                `}
              ></div>
              <div
                className={`
                  swiper-button-next-dopiverse
                  cursor-pointer
                  bg-[url('https://assets-dopinghafiza.dopingtech.net/assets/images/landing/dopiverse-arrow.png')]
                  bg-bottom
                  bg-contain
                  bg-no-repeat
                  w-[90px] h-[90px]
                  rotate-180
                  absolute
                  right-0
                  top-[50%]
                  -translate-y-[120%]
                `}
              ></div>
            </div>

            <Swiper
              pagination={pagination}
              loop={true}
              navigation={{
                nextEl: '.swiper-button-next-dopiverse',
                prevEl: '.swiper-button-prev-dopiverse',
              }}
              modules={[ Pagination, Navigation ]}
              className="relative z-10 !pt-20 overflow-visible sm:overflow-hidden [&>.swiper-pagination>.swiper-pagination-bullet-active]:!w-[18px] [&>.swiper-pagination>.swiper-pagination-bullet-active]:!opacity-100"
            >
              {
                dopiSlider.map((item) => (
                  <SwiperSlide key={item.id} className="!h-auto !flex items-stretch">
                    <div className="relative flex items-stretch px-1 sm:px-0">
                      <div
                        className="relative p-6 sm:p-0 rounded-[30px] sm:rounded-0 sm:!bg-none sm:!bg-transparent"
                        style={{ background: item.bg }}
                      >
                        <div className="flex flex-wrap text-white select-none">
                          <div className="relative w-full pb-10 sm:pb-0 lg:w-1/2">
                            <div className="relative block sm:hidden">
                              <Image
                                src={item.mobileImage.src}
                                alt="Doping Hafıza"
                                width={item.mobileImage.width}
                                height={item.mobileImage.height}
                                className="w-full h-auto"
                              />
                            </div>
                            <div className="leading-loose">
                              <h1 className="text-[28px] sm:text-4xl font-bold sm:font-black tracking-wide leading-tight mt-4 sm:mt-3 mb-7" dangerouslySetInnerHTML={purify(item.title)}></h1>
                              <p dangerouslySetInnerHTML={purify(item.content)}></p>
                            </div>
                          </div>
                          <div className="relative hidden sm:flex justify-center w-full lg:w-1/2 h-[400px] lg:h-auto items-end lg:items-start">
                            {
                              item.image.map((image) => (
                                <Image
                                  key={image.id}
                                  src={image.src}
                                  alt="Doping Hafıza"
                                  width={image.width}
                                  height={image.height}
                                  className="w-auto"
                                  style={{
                                    maxHeight: image.height + 'px',
                                    position: image?.absolute ? 'absolute' : 'relative',
                                    zIndex: image?.absolute ? 0 : 1,
                                    left: image?.absolute ? '50%' : 'auto',
                                    bottom: image?.absolute ? '0px' : 'auto',
                                    transform: image?.absolute ? 'translate(-160%, -10%)' : 'none',
                                  }}
                                />
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </section>

      {/* More from Dopiverse */}
      <section className="mt-24 py-20 bg-[#1C77A8]">
        <div className="container text-white text-center">
          <h2 className="text-[28px] lg:text-[40px] leading-tight font-bold mb-2" dangerouslySetInnerHTML={purify(dopiMore.title)}></h2>
          <p className="text-sm leading-loose" dangerouslySetInnerHTML={purify(dopiMore.content)}></p>

          <div className="flex justify-center flex-wrap mt-10 -mx-3">
            {
              dopiMore.images.map((item) => (
                <div key={item.id} className="w-full sm:w-1/2 lg:w-1/4 p-3">
                  <div className="relative w-full pb-6 lg:pb-0">
                    <Image
                      src={item.image}
                      alt="Doping Hafıza"
                      width={item.width}
                      height={item.height}
                      className="w-full h-auto"
                    />

                    <h3 className="text-base leading-normal font-semibold mt-4 mb-2" dangerouslySetInnerHTML={purify(item.caption)}></h3>
                    <div className="text-sm leading-loose" dangerouslySetInnerHTML={purify(item.content)}></div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      {/* Faq */}
      <section className="py-20 bg-white">
        <div className="container text-charcoal">
          <h2 className="text-[28px] lg:text-[40px] leading-tight font-bold text-center mb-2" dangerouslySetInnerHTML={purify('Sıkça Sorulan Sorular')}></h2>

          <div className="flex flex-col justify-center items-stretch mt-10 -mx-3">
            {
              dopiFaq.map((item: any) => {
                const ci = {
                  id: item.id,
                  container: null,
                  title: null,
                  content: null
                };

                faqItems.current.push(ci);
                const index = faqItems.current.length - 1;

                return (
                  <div key={item.id} className="relative p-3">
                    <div ref={ (el: any) => faqItems.current[index].container = el } className="overflow-hidden transition-all duration-200">
                      <div ref={ (el: any) => faqItems.current[index].title = el } className="relative pe-14 py-3" onClick={ () => toggleFaq(item.id) }>
                        <h3 className="font-bold text-[18px] cursor-default select-none" dangerouslySetInnerHTML={purify(item.title)}></h3>
                        <div className="absolute right-0 top-0 bg-honolulu-blue text-white py-3 px-4 rounded-xl text-center cursor-pointer select-none">
                          <DPIcon icon={item.id === faqActive ? 'minus' : 'plus'} />
                        </div>
                      </div>
                      <div ref={ (el: any) => faqItems.current[index].content = el } className="text-sm leading-loose pt-5 pe-0 sm:pe-14" dangerouslySetInnerHTML={purify(item.content)}></div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    </main>
  );
}
