"use client";

import React, { Children, useEffect, useId, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';

export default function SwipingTabs({ className, children }: {
  readonly className: string,
  readonly children: React.ReactElement|React.ReactElement[],
}) {

  const container = useRef<HTMLDivElement|null>(null);
  const itemWidth = 'max-w-[' + (100 / Math.max(1, Children.toArray(children).length)) + '%]';

  const tabId = useId();
  const tabs = Children.map(children, (child: React.ReactElement, index) => {
    if(child?.props?.title) return { id: tabId + '-' + index, child, title: child.props.title };
  });

  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState(0);
  const swiperRef = useRef<any>();
  
  const selectTab = (index: number) => {
    if(swiperRef.current?.swiper) {
      let fix = Math.floor((swiperRef.current.swiper.visibleSlides.length - 1) / 2);
      let moveTo = Math.max(0, Math.min(swiperRef.current.swiper.slides.length - 1, index - fix));
      swiperRef.current.swiper.slideTo(moveTo);
    }
    if(selectedTab !== index) {
      setSelectedTab(index);
    }
  };

  const detailRequested = (event: CustomEvent) => {
    setSelectedTab(event.detail.detailTabIndex);
  };

  useEffect(() => {
    setIsLoaded(true);

    window?.addEventListener('detailRequest', detailRequested as EventListener);

    return () => {
      window?.removeEventListener('detailRequest', detailRequested as EventListener);
    };
  }, []);

  return (
    <div ref={container} className={`${isLoaded ? 'transition-all opacity-100 duration-300' : 'opacity-0'}`}>
      <div className={`${className ?? ''}`}>
        <Swiper
          ref={swiperRef}
          loop={false}
          watchSlidesProgress={true}
          slidesPerView={'auto'}
          className="mb-6"
        >
          {
            tabs?.map((item: any, index: number) => {
              return (
                <SwiperSlide
                  key={item.id}
                  className={`flex justify-stretch min-w-min ${itemWidth} flex-grow !w-auto [&:nth-child(1)>div]:ms-0`}
                  onClick={ () => selectTab(index) }
                >
                  <div className={`
                    ${selectedTab === index ? 'before:h-[6px] before:!bg-honolulu-blue text-honolulu-blue' : 'before:h-[3px]'}
                    relative flex justify-start items-center my-1 ms-4 whitespace-nowrap py-3 text-sm font-medium cursor-pointer select-none
                    transition-all before:transition-all
                    before:absolute before:left-0 before:right-0 before:-bottom-[3px] before:bg-slate-400 hover:before:bg-honolulu-blue
                  `}>
                    { item.title }
                  </div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>

        {
          tabs?.map(({ id, child }, index: number) => {
            if(child?.props?.title) return (
              <div
                key={id}
                className={`${selectedTab === index ? 'block' : 'hidden'}`}
              >
                { child }
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
