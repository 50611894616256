"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import EmployeeCard from "@/components/EmployeeCard/EmployeeCard";
import { useEffect, useState } from "react";

export default function OurTeamMain({ data }: Record<string, any>) {
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ isMobile, setIsMobile ] = useState<boolean>(false);

  useEffect(() => {
    const listenResize = () => {
      if(window?.innerWidth < 1024 && !isMobile) setIsMobile(true);
      if(window?.innerWidth >= 1024 && isMobile) setIsMobile(false);
    };

    setIsLoaded(true);
    listenResize();

    window?.addEventListener('resize', listenResize);
    return () => {
      window?.removeEventListener('resize', listenResize);
    };
  }, [ isMobile ]);

  return (
    <div className={`relative`}>
      {
        (() => {
          if(data?.view?.caption ?? '') {
            return (
              <h1 className="font-bold text-[28px] pb-6">
                { data.view.caption }
              </h1>
            );
          }
        })()
      }
      
      <section className="relative -mt-3 -mx-4 min-h-lvh">
        {
          data?.view?.departments?.map((department: Record<string, any>) => (
            <div key={department.id} className="max-w-full">
              { department?.caption && isLoaded ? (<h2 className="font-bold text-2xl mx-4 py-3">{department?.caption}</h2>) : (<></>) }

              {
                isMobile ? (
                  <div className={`relative block lg:hidden mb-8`}>
                    <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={0}
                      watchSlidesProgress={true}
                      className="!overflow-visible !ps-2 !pe-10 sm:!ps-0 sm:!pe-0
                      [&>.swiper-wrapper>.swiper-slide]:transition-all [&>.swiper-wrapper>.swiper-slide]:opacity-40 [&>.swiper-wrapper>.swiper-slide-visible]:!opacity-100"
                      // If we want to hide left side of the first slide we can add these classes above -> before:top-0 before:bottom-0 before:right-[100%] before:w-[9999px] before:absolute before:z-10 before:bg-cultured
                    >
                      {
                        department?.employee?.map((employee: Record<string, any>) => (
                          <SwiperSlide key={employee.id} className="rounded-[10px] py-3 sm:max-w-[50%]">
                            <div className="w-full px-2 sm:px-3 md:px-4">
                              <EmployeeCard data={employee} />
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </div>
                ) : (
                  <div className="relative hidden lg:flex w-full flex-wrap mb-8">
                    {
                      department?.employee?.map((employee: Record<string, any>) => (
                        <div key={employee.id} className="py-3 px-4 w-1/3">
                          <EmployeeCard data={employee} />
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          ))
        }
      </section>
    </div>
  );
}
