"use client";
export default function NumberButton({ number, size, onClick, className }: {
  readonly number: number,
  readonly size?: number,
  readonly onClick?: Function,
  readonly className?: string,
}) {
  const handleClick = (e: any) => {
    if(onClick) onClick(e);
  };

  return (
    <div
      className="relative"
      style={{
        width: (size ?? 56) + 'px',
        height: (size ?? 56) + 'px',
      }}
      onClick={handleClick}
    >
      <div className={`absolute top-0 right-0 bottom-0 left-0 z-[1] flex justify-center items-center font-bold ${className ?? ''}`}>
        { number }
      </div>
      <svg width={size ?? 56} height={size ?? 56} viewBox={`0 0 ${size ?? 56} ${size ?? 56}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5918 56C18.382 55.9587 9.81468 51.3123 4.67195 43.5684C-0.126393 36.3449 -1.29115 27.3247 1.46789 18.8167C5.92184 5.1048 18.5871 0.107383 28.9539 0H29.2209C35.6793 0 41.5843 2.23851 46.3091 6.48012C51.2158 10.8869 54.4431 17.1399 55.6427 24.5741C56.8345 31.9628 55.0313 38.951 50.4342 44.7744C45.0515 51.5932 36.1282 56 27.704 56H27.5956H27.5918Z" fill="url(#paint0_radial_4_2)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5955 54.1988C18.9314 54.1617 10.8748 49.7962 6.04938 42.5272C1.57221 35.7827 0.484849 27.3573 3.06202 19.4069C7.22961 6.57882 19.1752 1.90355 28.9692 1.80029H29.2285C35.2767 1.80029 40.8064 3.89425 45.2216 7.85502C49.8188 11.9851 52.8487 17.8664 53.9747 24.8751C55.0776 31.7228 53.4059 38.203 49.1377 43.6093C44.0607 50.0398 35.6443 54.1947 27.7 54.1947H27.5993L27.5955 54.1988Z" fill="#3E2952" />
        <path fillRule="evenodd" clipRule="evenodd" d="M51.7541 22.5505C54.9736 41.1195 43.8794 52.3162 27.7972 52.3162C11.7151 52.3162 -1.1437 40.2315 3.91777 22.5505C7.20308 11.0771 16.6527 1.80093 27.863 1.80093C39.0733 1.80093 49.6258 10.2552 51.7541 22.5505Z" fill="url(#paint1_radial_4_2)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M49.1224 22.1536C51.7228 34.9115 43.0007 47.7974 29.0429 48.6069C14.9459 49.4246 4.78808 37.4226 6.21985 24.2517C7.4233 13.183 16.6601 5.07149 26.9533 4.63783C37.1691 4.20417 46.9322 11.4071 49.1263 22.1577L49.1224 22.1536Z" fill="#FF8A00" />
        <path style={{ mixBlendMode: "screen"}} opacity="0.43" fillRule="evenodd" clipRule="evenodd" d="M11.5792 21.423C14.9884 22.0178 35.42 30.1954 41.4721 26.0033C51.092 19.3456 42.2267 11.1432 41.6888 10.9863C41.6888 10.9863 30.9661 -0.19803 15.9945 9.27229C9.53607 13.357 7.57417 20.7209 11.5792 21.4189V21.423Z" fill="url(#paint2_radial_4_2)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4943 16.1649C13.077 19.2914 15.2169 16.8547 15.0737 14.6409C14.9382 12.5015 10.6391 14.4757 11.4943 16.1649Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7312 11.4817C15.3061 14.3893 27.4064 8.59067 26.2146 6.9345C23.6568 3.38675 12.8799 9.90817 13.7312 11.4817Z" fill="white" />
        <path style={{ mixBlendMode: "screen"}} opacity="0.26" fillRule="evenodd" clipRule="evenodd" d="M46.7775 31.4999C42.486 37.6 38.2449 40.0657 35.6329 41.5567C32.6843 43.2376 36.3372 45.3811 42.161 41.0528C47.6791 36.9557 46.7775 31.4999 46.7775 31.4999Z" fill="url(#paint3_radial_4_2)" />
        <defs>
          <radialGradient id="paint0_radial_4_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.9981 14.4925) scale(39.7101 42.3831)">
            <stop stopColor="#FFD5BD" />
            <stop offset="0.1" stopColor="#F8D0BF" />
            <stop offset="0.25" stopColor="#E4C2C6" />
            <stop offset="0.43" stopColor="#C5ABD1" />
            <stop offset="0.63" stopColor="#998CE1" />
            <stop offset="0.75" stopColor="#7A75ED" />
            <stop offset="0.97" stopColor="#7A82ED" />
          </radialGradient>
          <radialGradient id="paint1_radial_4_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.5573 27.0565) scale(24.2161 25.8462)">
            <stop stopColor="#786F71" />
            <stop offset="1" stopColor="#300060" />
          </radialGradient>
          <radialGradient id="paint2_radial_4_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.7156 16.3306) scale(14.751 15.7439)">
            <stop stopColor="#E4ECF0" />
            <stop offset="1" stopColor="#A6B4BD" />
          </radialGradient>
          <radialGradient id="paint3_radial_4_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7099 37.5505) scale(5.89732 6.29427)">
            <stop stopColor="#E4ECF0" />
            <stop offset="1" stopColor="#A6B4BD" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}
