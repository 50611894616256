"use client";

import {useEffect, useState} from 'react';
import '../../components/css/commentsAndAchievements.scss';
import FsLightbox from "fslightbox-react";
import VideoList from '../../components/VideoList';
import ImageList from '../../components/ImageList';
import { LinkType } from '@/components/FastMenu/FastMenu';
import _e from '@/helpers/texts';
import SideBar from '@/components/SideBar/SideBar';
import LoadingLink from '@/components/LoadingLink/LoadingLink';
import DPIcon from '@/components/DPIcon/DPIcon';

export default function TestimonialCategoryMain({ data, root }: {
  data: Record<string, any>,
  root: string,
}) {
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ selectedImage, setSelectedImageId ] = useState<number>(0);
  const [ toggler, setToggler ] = useState(false);
  
  const fsImages: string[] = [];

  function selectModalImageId(url: string) {
    const id = fsImages.indexOf(url);
    setSelectedImageId(id);
    setToggler((prevToggler) => !prevToggler);
  }

  let nextIndex: number|undefined;
  data.sidebar.forEach((item: Record<string,any>, index: number) => {
    if(item.id === data.id) nextIndex = index + 1;
  });

  if(nextIndex && nextIndex >= data.sidebar.length) nextIndex = undefined;
  const nextCategory = nextIndex ? data.sidebar?.[nextIndex] ?? undefined : undefined;

  if(data.is_video !== 'Evet') {
    data.testimonials?.forEach((testimonial: Record<string,any>) => {
      fsImages.push(testimonial.image_url);
    });
  }

  const links = (data.sidebar ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${root}/${item.slug}`,
      caption: item.caption,
      target: '_self',
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${root}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  const showCount = data?.is_video === 'Evet' ? 8 : 18;
  const [ show, setShow ] = useState<number>(1);
  const [ hasMore, setHasMore ] = useState<boolean>(false);

  const loadMore = () => {
    let current = show + 1;
    setHasMore((current * showCount) < (data.testimonials?.length ?? 0));
    setShow(current);
  };

  useEffect(() => {
    setHasMore(showCount < (data.testimonials?.length ?? 0));
    setIsLoaded(true);
  }, [ data.testimonials?.length, showCount ]);

  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={fsImages}
        types={fsImages.map(() => 'image')}
        sourceIndex={selectedImage}
      />

      <div className={`container mb-10 ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
        <div className="flex flex-col md:flex-row gap-6">
          <SideBar className="relative hidden lg:block w-1/4 bg-white rounded-lg shadow-md pt-3 pb-2 flex-grow-0 self-start flex-shrink-0" list={links}/>

          <main className="flex-1">
            <h3 className="text-[28px] font-semibold text-gunmetal mb-8">{data.caption}</h3>
            <section>
              
              {
                (data.is_video === 'Evet' ? (
                  <VideoList caption={data?.caption ?? ''} testimonials={data.testimonials?.slice(0, show * showCount)} urlRoot={`${root}/${data.slug}`} />
                ) : (
                  <ImageList caption={data?.caption ?? ''} testimonials={data.testimonials?.slice(0, show * showCount)} lbSelect={selectModalImageId} />
                ))
              }

              {
                (() => {
                  if(hasMore) {
                    return (
                      <div className={`flex justify-center items-center py-10`}>
                        <button className="btn std-btn" onClick={loadMore}>{ _e('Daha Fazla Göster') }</button>
                      </div>
                    );
                  }
                })()
              }

              {
                (() => {
                  if(nextCategory) {
                    return (
                      <div className="block w-full text-center py-10">
                        <LoadingLink href={`${root}/${nextCategory.slug}`} className="flex items-center text-lg justify-center text-lava font-semibold mx-auto" title={`Doping Hafıza ${nextCategory.caption}`}>
                          {nextCategory.caption}
                          <DPIcon icon="arrow-circle-right" className="text-lava text-2xl ml-2" />
                        </LoadingLink>
                      </div>
                    );
                  }
                })()
              }

            </section>
          </main>
        </div>
      </div>
    </>
  );
}
