"use client";
import ArticleCard from "@/components/ArticleCard/ArticleCard";
import _e from "@/helpers/texts";
import { useEffect, useState } from "react";

export default function BlogCategoryMain({ data }: {
  data: Record<string, any>,
}) {
  const articles = data?.view?.blog?.articles ?? [];
  articles.sort((a: Record<string, any>, b: Record<string, any>) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  const showCount = 8;
  const [ show, setShow ] = useState<number>(1);
  const [ hasMore, setHasMore ] = useState<boolean>(false);

  const loadMore = () => {
    let current = show + 1;
    setHasMore(current * showCount < articles.length);
    setShow(current);
  };

  useEffect(() => {
    setHasMore(showCount < articles.length);
  }, [ articles.length ]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[30px] ">
        {
          articles?.slice(0, show * showCount)?.map((article: Record<string, any>) => (
            <ArticleCard key={article.id}
              image={article?.image_url ?? '-'}
              link={`/${data.slug}/${article.slug}`}
              caption={article.caption}
              category={data.caption}
              date={article.date}
              className="mb-8"
              imgRoot="bizden-haberler"
            />
          ))
        }
      </div>

      <div className={`${hasMore ? 'flex' : 'hidden'} justify-center items-center py-10`}>
        <button className="btn std-btn" onClick={loadMore}>{ _e('Daha Fazla Göster') }</button>
      </div>
    </>  
  );
}
