"use client";

import EmployeeCard from "@/components/EmployeeCard/EmployeeCard";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { linkUrl } from "@/helpers";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function BoardMain({ data }: Record<string, any>) {
  const [ isMobile, setIsMobile ] = useState<boolean>(false);

  useEffect(() => {
    const listenResize = () => {
      if(window?.innerWidth < 1024 && !isMobile) setIsMobile(true);
      if(window?.innerWidth >= 1024 && isMobile) setIsMobile(false);
    };

    listenResize();

    window?.addEventListener('resize', listenResize);
    return () => {
      window?.removeEventListener('resize', listenResize);
    };
  }, [ isMobile ]);

  return (
    <div className="relative">
      <section className="relative -mt-3 -mx-4">
        <div className="max-w-full">
          { data?.caption ? (<h2 className="font-bold text-2xl mx-4 py-3">{data?.caption}</h2>) : (<></>) }

          {
            isMobile ? (
              <div className={`relative block lg:hidden mb-8`}>
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={0}
                  watchSlidesProgress={true}
                  className="!overflow-visible !ps-2 !pe-10 sm:!ps-0 sm:!pe-0
                  [&>.swiper-wrapper>.swiper-slide]:transition-all [&>.swiper-wrapper>.swiper-slide]:opacity-40 [&>.swiper-wrapper>.swiper-slide-visible]:!opacity-100"
                  // If we want to hide left side of the first slide we can add these classes above -> before:top-0 before:bottom-0 before:right-[100%] before:w-[9999px] before:absolute before:z-10 before:bg-cultured
                >
                  {
                    data?.members?.map((member: Record<string, any>) => (
                      <SwiperSlide key={member.id} className="rounded-[10px] py-3 sm:max-w-[50%]">
                        <div className="w-full px-2 sm:px-3 md:px-4">
                          <EmployeeCard data={member} />
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            ) : (
              <div className="relative flex w-full flex-wrap">
                {
                  data?.members?.map((member: Record<string, any>) => (
                    <div key={member.id} className="py-3 px-4 w-full sm:w-1/2 lg:w-1/3">
                      <EmployeeCard data={member} />
                    </div>
                  ))
                }
              </div>
            )
          }

          {
            data?.video_url ? (
              <div className="my-5 mx-4 py-3">
                { data?.video_caption ? (<h2 className="font-bold text-2xl mb-4">{data?.video_caption}</h2>) : (<></>) }

                <VideoPlayer
                  src={linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/tanitim/', data.video_url) ?? ''}
                  poster={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/videogorsel/', data?.video_poster_url ?? '') ?? ""}
                />
              </div>
            ) : (<></>)
          }
        </div>
      </section>
    </div>
  );
}
