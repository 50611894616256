"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import LoadingLink from "@/components/LoadingLink/LoadingLink";
import { linkUrl } from "@/helpers";
import { formatUrl } from "@/helpers/formatters";
import Image from "next/image";

export default function FaqCategoryMain({ data }: {
  readonly data: Record<string,any>,
}) {
  let hasIcon = (data?.dp_icon || data?.icon_url) ? true : false;

  return (
    <section>
      <div className="flex items-center">
        {
          (() => {
            return hasIcon ? (
              <>
                {
                  (() => {
                    return data?.dp_icon ? (
                      <DPIcon icon={data.dp_icon} className="text-5xl text-lava w-[72px]" />
                    ) : (
                      <div className="w-[72px] h-12 relative">
                        <Image
                          src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/', data?.icon_url ?? '') ?? ''}
                          fill={true}
                          sizes="100%"
                          alt={data?.name ?? 'Doping Hafıza'}
                          style={{ objectFit: 'contain', objectPosition: 'left center'}}
                        />
                      </div>
                    );
                  })()
                }
              </>
            ) : (<></>);
          })()
        }
        <span className="text-[26px] font-bold">{ data.name }</span>
      </div>

      <div>
        <ul>
          {
            data.faqs?.map((item: Record<string,any>) => (
              <li key={item.id} className={`${hasIcon ? 'sm:ps-[72px]' : ''} pt-5`}>
                <LoadingLink className="hover:text-lava transition-all" href={formatUrl(`${data.root}/${data.slug}/${item.slug}`, true)} title={item.caption}>{ item.caption }</LoadingLink>
              </li>
            ))
          }
        </ul>
      </div>
    </section>
  );
}
