"use client";

import { videoFunctions } from "@/components/HomeSlider/functions";
import JsonLd from "@/components/JsonLd/JsonLd";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { linkUrl } from "@/helpers";
import { useState } from "react";

export default function VideoList({ data }: { readonly data: Record<string,any>[] }) {
  const [ videos, setVideos ] = useState<{ id: string, video: HTMLVideoElement }[]>([]);
  const fn = videoFunctions({ videos, setVideos });

  return (
    <div className="flex justify-start items-stretch flex-wrap -mx-3">

      {
        data?.map((item) => (
          <div key={item.id} className="flex items-stretch p-3 w-full md:w-1/2 lg:w-1/3">
            <div className="p-3 relative shadow-md rounded-lg flex flex-col justify-between">
              <div>
                <VideoPlayer
                  src={ linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/', item.video_url ?? '') ?? '' }
                  poster={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/videogorsel/', item.video_poster_url ?? '') ?? '' }
                  onAdd={fn.addVideo}
                  onRemove={fn.removeVideo}
                />
                <div className="mt-3 text-base font-semibold">
                  { item?.caption }
                </div>
              </div>

              <div>
                <div className="text-xs flex justify-between items-end mt-3 pt-3 border-t-[1px] border-t-slate-300">
                  <span>{ item?.video_sub_category?.caption ?? item?.video_sub_category?.video_category?.caption ?? '' }&nbsp;</span>
                  <span>{ item?.duration ? Math.floor((item.duration ?? 0) / 60) + ':' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') : '' }</span>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      
      <JsonLd data={{
        "@context": "https://schema.org",
        "@type": "VideoGallery",
        "itemListElement": data?.map((item: Record<string,any>, index: number) => {
          return {
            "@type": "VideoObject",
            "position": index + 1,
            "name": item?.caption,
            "description": item?.subcap,
            "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? '',
            "duration": item?.duration ? 'PT' + Math.floor((item.duration ?? 0) / 60) + 'M' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
            "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? '',
            "uploadDate": item.publishedAt,
          };
        })
      }} />

    </div>
  );
}