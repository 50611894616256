"use client";

import { LinkType } from '@/components/FastMenu/FastMenu';
import JsonLd from '@/components/JsonLd/JsonLd';
import PackageUsageInfo from '@/components/PackageUsageInfo/PackageUsageInfo';
import SideBar from '@/components/SideBar/SideBar';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { gtmSender, linkUrl } from '@/helpers';
import { formatPackageEventItem } from '@/helpers/formatters';
import _e from '@/helpers/texts';
import { usePathname } from 'next/navigation';
import {useEffect, useRef, useState} from 'react';

export default function TestimonialDetailMain({ data, root }: {
  data: Record<string, any>,
  root: string,
}) {
  const pathname = usePathname();
  const pathRef = useRef<string>();

  const [ isLoaded, setIsLoaded ] = useState(false);

  useEffect(() => {
    if(data?.package?.id && pathRef.current !== pathname) {
      const eventVal: Record<string,any> = {
        "event": "view_item",
        "ecommerce": {
          "currency": "TRY",
          "items": []
        }
      };

      eventVal.ecommerce.items.push(formatPackageEventItem(data.package));

      gtmSender({ ecommerce: null });
      gtmSender(eventVal);
      pathRef.current = pathname;
    }
    setIsLoaded(true);
  }, [ pathname, data.package ]);

  const links = (data.sidebar ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${root}/${item.slug}`,
      caption: item.caption,
      target: '_self',
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${root}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  return (
    <>
      <div className={`container mb-10 ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
        <div className="flex flex-col md:flex-row gap-6">
          <SideBar className="relative hidden lg:block w-1/4 bg-white rounded-lg shadow-md pt-3 pb-2 flex-grow-0 self-start flex-shrink-0" list={links}/>

          <main className="flex-1">

            <div className="col-span-8 bg-white p-5 lg:p-10 rounded-[6px] mb-10">
              <h2 className="text-gunmetal font-semibold text-[28px] leading-10 mb-[30px]">
                <span className="">{ data?.name ?? '' }</span>
              </h2>
              <VideoPlayer
                src={linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.video_url) ?? ''}
                poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data?.image_url) ?? ''}
              />
              <JsonLd data={{
                "@context": "https://schema.org",
                "@type": "VideoObject",
                "name": data?.name ?? data?.content ?? data?.caption,
                "description": data?.content,
                "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data?.image_url) ?? '',
                "uploadDate": data.publishedAt,
                "duration": data?.duration ? 'PT' + Math.floor((data.duration ?? 0) / 60) + 'M' + ((data.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
                "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.video_url) ?? '',
              }} />
              
              <div className="text-white bg-cornflower-blue shadow-[0_8_24_rgba(11,106,178,0.1)] rounded-[6px] py-5 px-[10px] my-10">
                <span>{ data?.name ?? data?.caption ?? '' }</span>
              </div>

              {
                data?.name && data?.caption && (
                  <div className="mb-10">
                    <span>{ data.caption }</span>
                  </div>
                )
              }

              { data.package?.id && (
                <PackageUsageInfo
                  data={data.package}
                  packagesSlug={data.packages_slug}
                />
              ) }
            </div>

          </main>
        </div>
      </div>
    </>
  );
}
