"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import { Children, useCallback, useEffect, useId, useRef, useState } from "react";
import Image from "next/image";

export default function InfoItem({ id, title, className, toggler, toggleState, children, size, icon, image, onOpen, onClose, onToggle }: {
  readonly id?: string,
  readonly title: string,
  readonly className?: string,
  readonly toggleState?: string|number,
  readonly toggler?: Function,
  readonly register?: Function,
  readonly children?: any,
  readonly size?: 'thick' | 'thin',
  readonly icon?: string,
  readonly image?: string,
  readonly onOpen?: Function,
  readonly onClose?: Function,
  readonly onToggle?: Function,
}) {
  const contentWrapper = useRef<HTMLDivElement|null>(null);
  const contentOuter = useRef<HTMLDivElement|null>(null);
  const contentInner = useRef<HTMLDivElement|null>(null);

  const [isLoaded, setIsLoaded ] = useState<boolean>(false);

  const defId = useId();
  const tabId = id ? id : defId;

  const toggle = useCallback(() => {
    if(toggler) {
      const res = toggler(tabId);
      if(res) {
        if(onOpen) onOpen();
      } else {
        if(onClose) onClose();
      }
      
      if(onToggle) onToggle();
    }
  }, [
    toggler,
    tabId,
    onOpen,
    onClose,
    onToggle
  ]);

  const scrollToScreen = () => {
    const scrollThreshold = window?.innerWidth < 1024 ? {
      check: 30,
      apply: 120,
      delay: 300
    } : {
      check: 60,
      apply: 140,
      delay: 300
    };

    if(contentOuter.current?.style?.getPropertyValue('height') !== '0px') {
      setTimeout(() => {
        const dTop = document?.documentElement?.scrollTop;
        const eTop = contentWrapper.current
          ? contentWrapper.current!.getBoundingClientRect().top + dTop - scrollThreshold.check
          : 0;
        if(eTop < dTop) document?.documentElement?.scrollTo({ top: eTop - scrollThreshold.apply, behavior: 'smooth' });
      }, scrollThreshold.delay);
    }
  };
  
  useEffect(() => {
    const listenResize = () => {
      contentOuter.current?.style?.setProperty('height', `${(contentInner.current?.offsetHeight ?? 0) * (toggleState === tabId ? 1 : 0)}px`);
    };

    setIsLoaded(true);

    setTimeout(() => {
      listenResize();
      scrollToScreen();
    }, 5);

    window?.addEventListener('resize', listenResize);
    window?.addEventListener('manualresize', listenResize);
    return () => {
      window?.removeEventListener('resize', listenResize);
      window?.removeEventListener('manualresize', listenResize);
    };
  }, [ toggleState, tabId ]);

  return (
    <div ref={contentWrapper} className={`${className} ${isLoaded ? 'opacity-100 transition-opacity duration-300' : 'opacity-0'}`}>
      <div className="flex flex-col items-stretch bg-white rounded-lg shadow">
        <div className={`relative cursor-pointer text-base font-medium ${size === 'thin' ? 'ps-5 pe-9 py-6' : 'ps-6 pe-10 py-4 sm:!px-10 sm:py-9'} ${toggleState === tabId ? 'text-lava' : 'text-charcoal'}`} onClick={ toggle }>
          <div className="flex items-center">
            {
              (() => {
                if(icon) return (
                  <DPIcon icon={icon} className="text-2xl me-4" />
                );

                if(image) return (
                  <div className="h-6 overflow-hidden me-2">
                    <Image
                      src={image}
                      alt="Doping Hafıza"
                      width={40}
                      height={40}
                      className="w-auto h-auto max-h-full"
                    />
                  </div>
                );

                return (<></>);
              })()
            }
            { title }
          </div>
          <div className={`flex items-center justify-center absolute ${size === 'thin' ? 'end-6 top-[25px]' : 'end-5 sm:end-10 top-4 sm:top-9'}`}>
            <DPIcon icon={toggleState === tabId ? 'minus' : 'plus'} className="text-xl" />
          </div>
        </div>

        <div ref={contentOuter} className="transition-all duration-300 overflow-hidden" style={{ height: '0px' }}>
          <div ref={contentInner} className={`${size === 'thin' ? 'px-5 pb-8' : 'px-6 sm:px-10 pb-6 sm:pb-9'}`}>
            { Children.map(children, (child: any) => ( child )) }
          </div>
        </div>
      </div>
    </div>
  );
}
