import DPIcon from "@/components/DPIcon/DPIcon";

export default function InfoBtn({ title, onClick }: {
  readonly title?: string,
  readonly onClick?: Function,
}) {
  const handleClick = () => {
    if(onClick) onClick();
  };

  return (
    <div
      className="flex justify-center items-center text-center text-sm sm:text-base cursor-pointer !rounded-full max-w-max ps-8 pe-5 py-3 text-white font-semibold bg-honolulu-blue hover:bg-teal-700"
      onClick={handleClick}
    >
      {title} <DPIcon icon="arrow-right" className="text-3xl ms-2" />
    </div>
  );
}