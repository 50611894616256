"use client";

import EmployeeCard from "@/components/EmployeeCard/EmployeeCard";

export default function DepartmentMain({ data }: Record<string, any>) {
  return (
    <div className="relative">
      <section className="relative -mt-3 -mx-4">
        <div className="max-w-full">
          { data?.caption ? (<h2 className="font-bold text-2xl mx-4 py-3">{data?.caption}</h2>) : (<></>) }

          <div className="relative flex w-full flex-wrap">
            {
              data?.employee?.map((employee: Record<string, any>) => (
                <div key={employee.id} className="py-3 px-4 w-full sm:w-1/2 lg:w-1/3">
                  <EmployeeCard data={employee} />
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </div>
  );
}
