"use client";

import SampleVideoItem from "@/components/SampleVideo/SampleVideo";
import _e from "@/helpers/texts";
import { linkUrl, purify } from "@/helpers";
import { usePathname } from "next/navigation";
import PackageUsageInfo from "@/components/PackageUsageInfo/PackageUsageInfo";
import "./css/SampleVideoCategory.scss";
import VideoList from "../video-sub-categories/components/VideoList";
import { LinkType } from "@/components/FastMenu/FastMenu";
import SideBar from "@/components/SideBar/SideBar";

export default function SampleVideoCategory({ data }: {
  data: Record<string, any>,
}) {
  const pathname = usePathname();

  const links = (data.allCategories ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${data.videoRoot}/${item.slug}`,
      caption: item.caption,
      target: '_self',
      sublink: item?.video_sub_categories?.length > 1 ? item.video_sub_categories.map((sub: Record<string,any>) => {
        return {
          id: sub.id,
          url: `${data.videoRoot}/${item.slug}/${sub.slug}`,
          caption: sub.caption,
          target: 'self',
        };
      }) : undefined,
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${data.videoRoot}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  return (
    <div className="container">
      <div className="grid grid-cols-1 lg:grid-cols-11 gap-x-[30px] mb-10">
        <SideBar className="bg-white shadow-default col-span-3 h-fit rounded-[6px] hidden lg:block" list={links} />

        {
          (() => {
            if(data.videoList?.length) {
              return (
                <VideoList caption={data?.caption} videos={data?.videoList} root={data?.bypassRoot} />
              );
            }

            return (
              <div className="col-span-1 lg:col-span-8">
                <h1 className="text-2xl md:text-[28px] font-semibold mb-8">{ data?.caption }</h1>
                <div className="lg:bg-white grid grid-cols-1 lg:grid-cols-2 gap-x-[30px] lg:p-10 mb-4 lg:mb-10">
                  {
                    data?.video_sub_categories?.map((item: Record<string,any>) => (
                      <SampleVideoItem
                        key={item.id}
                        poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/files/ornekvideo/', item.card_image_url) ?? ''}
                        title={item.caption}
                        videoTime={`${item.videos?.count ?? 0} örnek video`}
                        categories="Örnek Videolar"
                        slug={`${pathname}/${item.slug}`}
                      />
                    ))
                  }
                </div>
                
                {
                  data?.package?.id && (
                    <div className="col-span-8 bg-white p-5 lg:p-10 rounded-[6px] mb-10">
                      <div className="mb-5 __sample-video-category-content-container" dangerouslySetInnerHTML={
                        purify(_e(
                          'Bu tarz öğretim teknikleri hoşunuza gittiyse <strong>Doping Hafıza :package_name</strong> paketlerini inceleyebilir ve çok daha fazlasına erişebilirsiniz.',
                          {
                            'package_name': data.package?.caption
                          }
                        ))
                      }></div>
      
                      <PackageUsageInfo
                        data={data.package}
                        packagesSlug={data.packages_slug}
                      />
                    </div>
                  )
                }
              </div>
            );
          })()
        }
      </div>
    </div>
  );
}
