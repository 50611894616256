"use client";

import React, {useRef, useState} from 'react';
import DPIcon from '@/components/DPIcon/DPIcon';
import { purify } from '@/helpers';
import _e from '@/helpers/texts';
import SimpleToaster from '@/components/SimpleToaster/SimpleToaster';
import { fetchMailer } from '@/helpers/fetchers/api-fetcher';

interface IFormData {
  name: string;
  email: string;
  phone: string;
  department: string;
  message: string;
}

interface IFormErrors {
  name?: string;
  email?: string;
  phone?: string;
  department?: string;
  message?: string;
}

export default function ContactMain({ data }: {
  readonly data: Record<string,any>
}) {
  const toasterRef = useRef<any>(null);
  const [ sending, setSending ] = useState<boolean>(false);

  const view: Record<string,any> = data.view?.[0] ?? {};

  const nameRegex = /^[A-Za-zÇŞĞÜÖİçşğüöı]+( [A-Za-zÇŞĞÜÖİçşğüöı]+){1,2}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\([1-9][0-9][0-9]\) \d{3} \d{2} \d{2}$/;
  const messageRegex = /^.{1,1023}$/;

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    email: '',
    phone: '',
    department: '',
    message: ''
  });

  // Hatalar için state
  const [errors, setErrors] = useState<IFormErrors>({});

  const formatPhoneNumber = (input:any) => {
    // Sadece sayısal karakterleri al
    const digits = input.replace(/\D/g, '');
  
    if (digits.length < 4) return digits;
    if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    if (digits.length < 9) return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6, 8)} ${digits.slice(8, 10)}`;
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;

    if (name === 'name' && nameRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, name: undefined }));
    }

    if (name === 'email' && emailRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, email: undefined }));
    }

    if (name === 'phone') {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevState) => ({ ...prevState, [name]: formattedValue }));
  
      // Telefon numarası her değiştiğinde hata mesajını kaldır
      setErrors((prevState) => ({ ...prevState, phone: undefined }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
      // Diğer alanlar için hata kontrolü...
    }

    if (name === 'department' && value !== '') {
      setErrors((prevState) => ({ ...prevState, department: undefined }));
    }

    if (name === 'message' && messageRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, message: undefined }));
    }
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();

    const newErrors: IFormErrors = {};

    if (!formData.name || !nameRegex.test(formData.name?.trim() ?? '')) {
      newErrors.name = _e("Lütfen geçerli bir ad soyad giriniz.");
    }
    if (formData.name?.length > 255) {
      newErrors.name = _e("İsim bilgisi :len karakterden uzun olamaz.", { len: 255 });
    }
    
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = _e("Lütfen geçerli bir e-posta adresi giriniz.");
    }
    if (formData.email?.length > 255) {
      newErrors.name = _e("Eposta bilgisi :len karakterden uzun olamaz.", { len: 255 });
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = _e("Lütfen geçerli bir telefon numarası giriniz.");
    }

    if (!formData.department || formData.department === '' || !([
      _e('Genel Bilgi'),
      _e('Satış Departmanı'),
      _e('Teknik Departman'),
      _e('İçerik Departmanı')
    ].includes(formData?.department))) {
      newErrors.department = _e("Lütfen bir departman seçiniz.");
    }

    if (!formData.message) {
      newErrors.message = _e("Lütfen bir mesaj giriniz.");
    }
    if (formData.message?.length > 1023) {
      newErrors.name = _e("Mesaj :len karakterden uzun olamaz.", { len: 1023 });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});

      (async () => {
        setSending(true);

        const res = await fetchMailer(formData);

        if (res.status === 200) {
          const response = await res.data;
          if(response?.success) {
            toasterRef.current.publish(_e('Mesajınız başarıyla gönderildi.'), 'success');

            let interval = Number(process.env.NEXT_PUBLIC_INTERVAL_PER_CONTACT_FORM_IN_MINUTES ?? 1);
            if(!interval || isNaN(interval)) interval = 1;
            interval = Math.max(1, interval);
            setTimeout(() => setSending(false), interval * 60000);
          } else if(response?.error) {
            toasterRef.current.publish(response.error);
            setSending(false);
          } else {
            toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
            setSending(false);
          }
        } else {
          toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
          setSending(false);
        }
      })();
    }
    
  };
  return (
    <>
      <SimpleToaster ref={toasterRef} />
      <div className="container mb-10">
        <h3 className="text-gunmetal font-semibold text-md md:text-[28px]">{ data.caption }</h3>
        <div className="bg-white rounded-md shadow-sm p-4 mt-4 md:p-8 md:mt-8">
          <div className="font-bold text-2xl mb-4">{ view.top_block_caption }</div>
          <div className="text-sm" dangerouslySetInnerHTML={purify(view.top_block_content)}></div>
          <div className="flex flex-col md:flex-row gap-[30px] lg:gap-[10px] mb-[80px] mt-8">
            { view.landline_number && (
              <div className="phone flex border border-lava text-lava rounded-[10px] py-[10px] px-[17px]">
                <div className="mr-4 flex items-center">
                  <DPIcon icon="navicons-phone" className="text-3xl text-lava" />
                </div>
                <div>
                  <div className="font-bold text-xs leading-6">{ _e('Sabit Telefon Hattımız') }</div>
                  <a className="font-bold text-xl" href={`tel:${view.landline_number.replace(/[\D]/g, '')}`} target="_blank">{view.landline_number}</a>
                </div>
              </div>
            ) }
            { view.whatsapp_number && (
              <div className="whatsapp flex border border-[#67c15e] text-[#67c15e] rounded-[10px] py-[10px] px-[17px]">
                <div className="mr-4 flex items-center">
                  <DPIcon icon="whatsapp-original" className="text-3xl text-[#67c15e]" />
                </div>
                <div>
                  <div className="font-bold text-xs leading-6">{ _e('Whatsapp Hattımız') }</div>
                  <a className="font-bold text-xl" href={`https://wa.me/90${parseInt(view.whatsapp_number.replace(/[\D]/g, '')).toString()}?text=Merhaba%2C%20bilgi%20almak%20istiyorum%21`} target="_blank">{view.whatsapp_number}</a>
                </div>
              </div>
            ) }
            { view.mobile_number && (
              <div className="whatsapp flex border border-blue-munsell text-blue-munsell rounded-[10px] py-[10px] px-[17px]">
                <div className="mr-4 flex items-center">
                  <DPIcon icon="mobile" className="text-3xl text-blue-munsell" />
                </div>
                <div>
                  <div className="font-bold text-xs leading-6">{ _e('Mobil Telefon Hattımız') }</div>
                  <a className="font-bold text-xl" href={`tel:${view.mobile_number.replace(/[\D]/g, '')}`} target="_blank">{ view.mobile_number }</a>
                </div>
              </div>
            ) }
          </div>

          { view.map_frame && (
            <div className="rounded-md overflow-hidden mt-12 md:mt-20">
              <iframe 
                src={view.map_frame} 
                width="600" 
                height="450" 
                allowFullScreen={true} 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full"
                title="Google Maps">
              </iframe>
            </div>
          ) }
        </div>
        <div className="bg-white rounded-md shadow-sm p-5 mt-4 md:p-10 md:mt-8">
          <div className="font-bold text-2xl mb-12 lg:mb-4">{ _e('Hızlı İletişim Formu') }</div>
          <form className="grid grid-cols-1 md:grid-cols-2 gap-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className='lg:min-h-[80px]'>
                <input 
                  type="text" 
                  placeholder="Ad & Soyad" 
                  maxLength={255}
                  className={errors.name ? 'w-full border border-lava rounded-md py-2 px-6 h-16' : 'w-full border border-gray-300 rounded-md py-2 px-6 h-16'} 
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
                {errors.name && <p className="error-message text-xs text-lava">{errors.name}</p>}
              </div>
              <div className='lg:min-h-[80px]'>
                <input 
                  type="text" 
                  placeholder="E-Posta Adresi"
                  maxLength={255}
                  className={errors.email ? 'w-full border border-lava rounded-md py-2 px-6 h-16' : 'w-full border border-gray-300 rounded-md py-2 px-6 h-16'} 
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                />
                {errors.email && <p className="error-message text-xs text-lava">{errors.email}</p>}
              </div>
              <div className='lg:min-h-[80px]'>
                <input 
                  type="text" 
                  placeholder="Telefon Numarası" 
                  className={errors.phone ? 'w-full border border-lava rounded-md py-2 px-6 h-16' : 'w-full border border-gray-300 rounded-md py-2 px-6 h-16'} 
                  name="phone"
                  maxLength={255}
                  onChange={handleChange}
                  value={formData.phone}
                  max={10}
                />
                {errors.phone && <p className="error-message text-xs text-lava">{errors.phone}</p>}
              </div>
              <div className='lg:min-h-[80px]'>
                <div className="relative">
                  <select 
                    className={errors.department ? 'relative w-full border border-lava rounded-md py-2 px-6 h-16 text-gray-400 appearance-none' : 'relative w-full border border-gray-300 rounded-md py-2 px-6 h-16 text-gray-400 appearance-none'}
                    name="department"
                    onChange={handleChange}
                    value={formData.department}
                  >
                    <option value="">{ _e('Departman Seçiniz') }</option>
                    <option>{ _e('Genel Bilgi') }</option>
                    <option>{ _e('Satış Departmanı') }</option>
                    <option>{ _e('Teknik Departman') }</option>
                    <option>{ _e('İçerik Departmanı') }</option>
                  </select>
                  <DPIcon
                    icon="direction"
                    className="text-xl text-gray-400 absolute top-1/2 right-4 transform -translate-y-1/2"
                  />
                </div>
                {errors.department && <p className="error-message text-xs text-lava">{errors.department}</p>}
              </div>
            </div>
            <div>
              <textarea
                placeholder="Mesajınız" 
                className={`w-full border rounded-md px-6 py-6 h-32 md:h-[151px] lg:h-[159px] resize-none ${errors.message ? 'border-red-500' : 'border-gray-300'}`} name="message"
                onChange={handleChange}
                value={formData.message}
                maxLength={1023}
              ></textarea>
              {errors.message && <p className="error-message text-xs text-lava">{errors.message}</p>}
            </div>
          </form>
          <div className="flex justify-end">
            <button 
              className={`bg-lava text-white rounded-lg px-8 py-4 mt-4 font-semibold transition-all duration-300 ${sending ? 'grayscale pointer-events-none' : ''}`}
              onClick={handleSubmit}
            >
              { _e('Gönder') }
            </button>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-sm p-4 mt-4 md:p-8 md:mt-8">
          <div className="font-bold text-2xl mb-4">{ view?.firm_block_caption }</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xs">
            <div className="left [&>*:nth-child(even)]:bg-[#fcfcfc] [&>*:nth-child(odd)]:bg-white">
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Tescilli Marka Adı') }</span>
                <span className="font-bold">{ view.brand_name }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('İşletme Adı') }</span>
                <span className="font-bold">{ view.firm_name }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Şirketin MERSİS Numarası') }</span>
                <span className="font-bold">{ view.mersis_no }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Ticaret Ünvanı') }</span>
                <span className="font-bold">{ view.firm_title }</span>
              </div>
            </div>
            <div className="right [&>*:nth-child(even)]:bg-[#fcfcfc] [&>*:nth-child(odd)]:bg-white">
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Adres') }</span>
                <span className="font-bold">{ view.firm_address }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('KEP Adresi') }</span>
                <span className="font-bold">{ view.kep_address }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Vergi Müdürlüğü') }</span>
                <span className="font-bold">{ view.tax_auth }</span>
              </div>
              <div className="flex justify-between gap-4 py-6 px-4 mb-2">
                <span>{ _e('Ticaret Sicil No') }</span>
                <span className="font-bold">{ view.tax_reg_no }</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
