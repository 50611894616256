"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "./css/scientificMain.scss";
import { Navigation, Pagination, Autoplay, Thumbs, FreeMode } from "swiper/modules";
import { useState } from "react";
import { linkUrl, purify } from "@/helpers";
import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";

export default function ScientificMain({ data }: Record<string, any>) {
  const fallback_image = 'https://assets-dopinghafiza.dopingtech.net/assets/images/infografik/banner3.png';

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleSwiper = (swiper: any) => {
    setThumbsSwiper(swiper);
  };

  return (
    <div className="pb-24">
      <Swiper
        spaceBetween={10}
        navigation={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[
          FreeMode,
          Pagination,
          Autoplay,
          Navigation,
          Thumbs,
        ]}
      >
        {
          data.view?.slides?.map((slide: Record<string,any>) => (
            <SwiperSlide key={slide.id}
              className="p-5" style={{background: `linear-gradient(90deg, ${slide?.start_color ?? '#0166fc'} 0%, ${slide?.mid_color ?? '#0157ec'} 45%, ${slide?.end_color ?? '#0129bc'} 100%)`}}>
              <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center ">
                  <div className="text-center lg:text-left">
                    <div>
                      <p className="text-[16px] lg:text-[37px]  font-bold text-white">{slide?.caption ?? ''}</p>
                      <p className="text-[#c0e4f9] text-[15px] mt-2 lg:text-xl mb-4">{slide?.subcap ?? ''}</p>
                    </div>
                    <div className="pr-0 lg:pr-[87px]  text-[13px] lg:text-[16px] leading-[22px] lg:leading-9 text-white" dangerouslySetInnerHTML={purify(slide?.short_content ?? '')}></div>
                  </div>
                  <div>
                    <ImageWithFallback
                      src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/infografik/', slide?.image_url ?? 'banner3.png') ?? fallback_image}
                      fallback={fallback_image}
                      alt={slide?.caption ?? 'Doping Hafıza'}
                      width={500}
                      height={500}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>

      <Swiper
        autoHeight={true}
        onSwiper={handleSwiper}
        spaceBetween={10}
        slidesPerView={1}
        freeMode={true}
        allowTouchMove={false}
        effect="fade"
        watchSlidesProgress={true}
        modules={[Navigation, Thumbs]}
      >
        {
          data.view?.slides?.map((slide: Record<string,any>) => (
            <SwiperSlide key={slide.id}
              className="__scientific-page-slide-sub-content p-5">
              <div className="container">
                <div className={`grid grid-cols-1 ${slide?.sub_content_end ? 'lg:grid-cols-2' : 'lg:grid-cols-1'} gap-4 items-start`}>
                  <div className="text-[14px] bg-white rounded-md p-10 shadow-md" dangerouslySetInnerHTML={purify(slide?.sub_content_start ?? '')}></div>
                  {
                    (() => {
                      if (slide?.sub_content_end) {
                        return (
                          <div className="text-[14px] bg-white rounded-md p-10 shadow-md" dangerouslySetInnerHTML={purify(slide.sub_content_end)}></div>
                        );
                      }
                    })()
                  }
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
}
