"use client";

import _e from "@/helpers/texts";
import DPIcon from "../DPIcon/DPIcon";

export default function StartLiveChat() {
  const triggerLiveChat = () => {
    window?.dispatchEvent(new CustomEvent('maximize-live-chat', { detail: {} }));
  };

  return (
    <div className="w-full bg-[#4C3572] rounded-[6px] hidden lg:flex flex-col  md:flex-row justify-between pl-[20px] md:pl-[50px] py-[20px] pr-[20px] items-center text-white">
      <div className="relative bg-white w-[60px] h-[60px] rounded-[50%] hidden xl:block">
        <div className="absolute top-2/4 left-2/4 -translate-x-1/2	-translate-y-1/2 text-[24px]">
          <DPIcon icon="chat" className="text-[24px] text-charcoal" />
        </div>
      </div>
      <span className="text-[14px] text-center [&>p]:m-0">
        { _e('Dilerseniz Sorularınızı Yanıtlayalım merak ettiklerinizi en hızlı şekilde cevaplayabiliriz.') }
      </span>
      <button onClick={triggerLiveChat} className="p-[12px] w-[190px] mt-[20px] md:mt-[0px] h-[54px] rounded-[50px] border text-[14px] font-medium">
        { _e('Konuşma Başlat') }
      </button>
    </div>
  );
}
