"use client";
import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import NumberButton from "./NumberButton";
import { purify } from "@/helpers";

export default function ItemBox({ caption, content, image, altText, number, fallback }: {
  readonly caption: string,
  readonly content?: string,
  readonly image: string,
  readonly fallback?: string,
  readonly altText?: string,
  readonly number: number,
}) {
  return (
    <div className="relative w-full">
      <div className="flex justify-start items-center gap-2 mb-3">
        <NumberButton number={number} className="select-none text-black text-2xl" />
        <span className="font-semibold text-xl" dangerouslySetInnerHTML={purify(caption)} />
      </div>
      <div className="mb-3">
        <ImageWithFallback
          src={image}
          fallback={fallback ?? "/assets/images/ilkokul/box-items/box-image.png"}
          alt={altText ?? "Doping Hafıza"}
          loading="lazy" //! priority={true}
          width={345}
          height={230}
          className="w-full drop-shadow-[0_30px_30px_rgba(86,48,134,0.3)] select-none"
        />
      </div>
      <div className="text-sm text-center" dangerouslySetInnerHTML={purify(content)} />
    </div>
  );
}