/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import DPIcon from "@/components/DPIcon/DPIcon";
import { purify } from "@/helpers";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export interface ACOption {
  id: number;
  caption: string,
  slug: string,
  category: {
    id: number,
    name: string,
    slug: string,
  },
  isVisible?: boolean
}

interface AutoCompleteProps {
  options?: ACOption[];
  value: string;
  placeholder: string;
  root: string,
  onChange: (value: string) => void;
}

export default function AutoComplete({
  options = [],
  value,
  placeholder,
  root,
  onChange,
}: AutoCompleteProps) {
  const autocomplete = useRef<HTMLDivElement>(null);
  const [optionsData, setOptionsData] = useState<ACOption[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);

  const handleInputChange = (valueEvent: string) => {
    onChange(valueEvent); // OnChange fonksiyonunu çağırarak döndürülen değeri kullanıyoruz
    setOptionsData(() => {
      options.forEach((item) => {
        const pattern = new RegExp(`(${valueEvent})`, "gi");
        item.isVisible = pattern.test(item.caption);
      });

      return valueEvent === ""
        ? []
        : options.filter((item) =>
          item.caption
            .toLowerCase()
            .includes(valueEvent.toLowerCase()));
    });
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      autocomplete.current &&
      !autocomplete.current.contains(e.target as Node)
    ) {
      setIsShow(false);
    }
  };

  const highlightSearchText = (text: ACOption) => {
    const pattern = new RegExp(`(${value})`, "gi");
    const highlightedText = text.caption.replace(
      pattern,
      `<b>${value}</b>`
    );
    return highlightedText;
  };

  useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOptionsData(value === "" ? [] : options);
  }, [value, options]);

  useEffect(() => {
    setIsShow(optionsData.length !== 0);
  }, [optionsData]);

  return (
    <div ref={autocomplete} className="relative">
      <div className="relative">
        <DPIcon
          icon="search-article"
          className="mx-2 absolute top-1/2 -translate-y-1/2 left-[20px] z-50 text-[34px] text-[#0b6ab2]"
        />
        <input
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value)
          }
          className="relative w-full text-[20px] text-black border-2 rounded-[8px] py-0 px-[12px] h-[88px] outline-none pl-[80px]"
        />
      </div>
      {isShow && (
        <ul className="flex flex-col items-center justify-start mt-[10px] absolute top-[68px] z-50 bg-white rounded-[4px] w-full max-h-[240px] overflow-y-auto">
          {
            optionsData.map((searchItem) => {
              if (searchItem.isVisible) {
                return (
                  <LoadingLink
                    href={`${root}/${searchItem.category.slug}/${searchItem.slug}`}
                    key={searchItem.id}
                    className="w-full text-center "
                  >
                    <li
                      onClick={() => {
                        onChange(searchItem.caption);
                        setIsShow(false);
                      }}
                      className="text-left py-[23px] px-[40px] w-full bg-white outline-none border-none cursor-pointer hover:bg-blue-100"
                    >
                      <div
                        className="!text-[#3a4759]"
                        dangerouslySetInnerHTML={purify(highlightSearchText(searchItem))}
                      />
                    </li>
                  </LoadingLink>
                );
              }
            })
          }
        </ul>
      )}
    </div>
  );
}
