"use client";

import { durationMinutes, durationSeconds } from "@/helpers/fetchers/package";
import _e from "@/helpers/texts";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

export default function PackageContent({ data }: {
  readonly data: Record<string,any>,
}) {
  return (
    <div className="-mx-3">
      <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 1024: 2}}>
        <Masonry>
          {
            data?.units?.map((unit: Record<string,any>) => (
              <div key={unit.unitId} className={`p-3`}>
                <div className="shadow-md rounded-md">
                  <div className="text-honolulu-blue font-semibold p-4">
                    { unit.unitName }
                  </div>
                  <ul className="[&>li]:p-4 [&>li:nth-child(odd)]:bg-gray-50 text-xs font-semibold">
                    {
                      unit?.materials?.map((material: Record<string,any>) => (
                        <li key={material.materialId}>
                          { material.materialName }
                        </li>
                      ))
                    }
                  </ul>
                  <div className="bg-slate-100 flex justify-between items-center p-4 text-xs">
                    <strong>{ _e('TOPLAM') }</strong>
                    <span>{ durationMinutes(unit.totalContentDuration) + ':' + (durationSeconds(unit.totalContentDuration) % 60) } dk</span>
                  </div>
                </div>
              </div>
            ))
          }
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}
