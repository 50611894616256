"use client";

import { useEffect, useRef, useState } from "react";
import styles from "./css/MostPreferredPackages.module.scss";

import { formatENetValues, formatSlug, insertENetValues } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import { getRemainingDays, initPackageCategories, loadMore } from "./functions";
import PackageListSelector from "./PackageListSelector/PackageListSelector";
import JsonLd from "../JsonLd/JsonLd";
import { linkUrl } from "@/helpers";
import LoadingLink from "../LoadingLink/LoadingLink";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";

/*
RowsPerPage should either be -1 or a multiple of 3
If not it will be converted to a multiple of 3
If no rowsPerPage value is sent, it will be considered as 3

Case rowsPerPage = -1: list all items, don'show "load more" button
Case else: list indicated amount of items and show a "load more" button if there are more items

NoneSwiping is a boolean that indicates if the package list should be swipable or not when view size is mobile
*/
export default function SinglePackage({ packageData, rowsPerPage, noneSwiping, hasCategoryLink, subCap, className, gtmListId, hideRemaining, dialogRef }: {
  readonly packageData: Record<string,any>,
  readonly rowsPerPage?: number,
  readonly noneSwiping?: boolean,
  readonly hasCategoryLink?: boolean,
  readonly subCap?: string,
  readonly className?: string,
  readonly gtmListId?: string,
  readonly hideRemaining?: boolean,
  readonly dialogRef?: any,
}) {
  const selfDialogRef = useRef<any>(null);

  // Convert rowsPerPage to a multiple of 3
  const rpp = Math.ceil(Math.max(rowsPerPage ?? 3) / 3) * 3;

  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ remainingDayCount, setRemainingDayCount] = useState<number>();
  const [ packageCategory, setPackageCategory] = useState(() => {
    const asArr = initPackageCategories([ packageData ], rpp);
    return asArr[0] ?? undefined;
  });

  const loadMoreHandler = (page: number) => {
    loadMore(page, rpp, 0, setPackageCategory);
  };

  useEffect(() => {
    setRemainingDayCount(getRemainingDays(packageCategory?.exam?.date));

    setIsLoaded(true);
  }, [ packageCategory ]);

  return (
    <>
      {
        !dialogRef && (
          <BuyInfoDialog ref={selfDialogRef} />
        )
      } 
      <div className={`container ${styles['our-packages']} ${className ?? ''} ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0 min-h-[600px]'}`}>
        <h2 className={`flex flex-col lg:flex-row justify-between items-start lg:items-center text-2xl leading-tight font-bold ${remainingDayCount ? '-mt-2 -mb-1' : 'mb-1'}`}>
          {
            (() => {
              if(subCap) {
                return (
                  <div className="">
                    <div className="text-sm lg:text-3xl lg:leading-[50px] font-semibold">{ subCap }</div>
                    <div className="text-[26px] lg:text-[40px] lg:leading-[60px] font-bold text-lava">{ packageCategory.caption }</div>
                  </div>
                );
              } else return (
                <>{ packageCategory.caption }</>
              );
            })()
          }

          {
            (() => {
              if(hasCategoryLink) {
                return (
                  <LoadingLink href={formatSlug(packageCategory.slug, packageData?.packages_slug ?? '')} className="!flex items-center text-center mt-3 lg:mt-0 text-sm lg:text-base btn btn-red !rounded-full">
                    { _e('Tüm :name paketlerini göster', { name: packageCategory.caption.replace(' Paketleri', '') }) }
                  </LoadingLink>
                );
              } else if(!hideRemaining && remainingDayCount) return (
                <span className="hidden lg:flex flex-col items-center sm:text-base text-charcoal px-8 py-6 bg-white shadow-lg rounded-lg cursor-default -mt-6">
                  <div className="text-center text-xl font-semibold mb-2">
                    {
                      packageCategory.exam?.remaining_caption
                        ? packageCategory.exam?.remaining_caption
                        : _e('Sınava Kalan')
                    }
                  </div>
                  <div className="text-center text-4xl font-bold text-medium-purple">{ remainingDayCount } { _e('Gün') }</div>
                </span>
              );
            })()
          }
        </h2>
        
        <div className="max-w-full w-full">
          <div className="lg:mx-0">
            <PackageListSelector
              slugPrefix={formatSlug(packageCategory?.slug ?? '', packageData?.packages_slug ?? '')}
              noneSwiping={noneSwiping}
              data={packageCategory}
              loadMoreHandler={loadMoreHandler}
              rowsPerPage={rowsPerPage}
              gtmListId={gtmListId}
              dialogRef={dialogRef ?? selfDialogRef}
            />

            <JsonLd data={{
              "@context": "https://schema.org",
              "@type": "ProductCollection",
              "itemListElement": packageCategory?.packages?.map((item: Record<string,any>, index: number) => {
                return {
                  "@type": "Product",
                  "position": index + 1,
                  "name": item.caption,
                  "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', item.image_url),
                  "description": insertENetValues(item?.content ?? '', formatENetValues({ ...(item?.curriculums?.credits ?? {}), ...{ Sezon: item.season, 'Paket Adı': item.caption } }), true),
                  "brand": {
                    "@type": "Brand",
                    "name": "Doping Hafıza"
                  },
                  "offers": {
                    "@type": "Offer",
                    "priceCurrency": "TRY",
                    "price": item.price,
                    "url": formatSlug(item.slug, formatSlug(packageCategory?.slug ?? '', packageData?.packages_slug ?? ''))
                  }
                };
              })
            }} />

          </div>
        </div>
      </div>
    </>
  );
}
