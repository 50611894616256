"use client";

import { Children } from "react";

export default function TabContent({ title, className, children }: {
  readonly title: string,
  readonly className?: string,
  readonly children?: any,
}) {

  return (
    <div className={className ?? ''} title={title}>
      {
        Children.map(children, (child: any) => {
          return child;
        })
      }
    </div>
  );
}
